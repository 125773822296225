import { theme as uopxMuiTheme } from '@uopx/mui-style';
import { createTheme } from '@mui/material/styles';

const uopxTheme = createTheme(uopxMuiTheme);

const theme = createTheme(uopxTheme, {
  palette: {
    ...uopxMuiTheme.palette,
    custom: {
      brandRed: '#DD3826',
      darkGrey: '#313C45',
      blueGrey: '#667982',
      inactiveGrey: '#d8d8d8',
      skyBound: '#0d819c',
      darkBlack: '#12262D',
      lightGray: '#EBE8E8',
      warningRajah: '#F6B664',
      lightRajah: '#F6B6641A',
      rajahTint: '#F6B56325',
      errorLightOrange: '#FFF1E4',
      warningRed: '#DB372533',
      warningTint: '#DB372585',
      secondarySuccess: '#008563',
      secondarySuccessTint: '#00856310',
      deepGreenTint: '#56724885',
      orange: '#F67B37',
      orangeTint: '#F67B3785',
      darkInfoBlue: '#D6F3FF',
      lightInfoBlue: '#004F70',
      infoBlue: '#00638C',
      warnOrange: '#E06C00',
      buttonHoverAlt: '#FEF7F6',
      approved: '#007558',
      pending: '#AE5707',
      chromaticGreen: '#81CEBB',
      darkGreen: '#03634B',
    },
    confetti: {
      main: '#DB3725',
      lightMain: '#EA695B',
      muteBlue: '#33728C',
      lightMuteBlue: '#99CACF',
      forestGreen: '#5f8b68',
      mint: '#95CAA5',
      sunriseOrange: '#F89A43',
      orangeCreamsicle: '#F6B563',
    },
    veteransDayConfetti: {
      white: '#FFFFFF',
      blue: '#33728C',
      red: '#DC3727',
      black: '#12262D',
    },
    donut: {
      coral: '#ca593e',
      maize: '#e5bd7d',
      seafoam: '#a9bab2',
      teal: '#487a92',
      navyTeal: '#30718d',
      babyMelonGreen: '#93cba4',
      orange: '#f67b37',
      darkShadeRed: '#8a1500',
      lightOrange: '#EC712D',
      brightOrange: '#D84D0B',
      burntOrange: '#C85526',
      sunriseOrange: '#F67B39',
      green: '#388164',
      systemGreen: '#008A68',
      dangerRed: '#B21F18',
      phoenixRed: '#B21F12',
      grey: '#D2D7D9',
    },
    calendar: {
      lightBlue: '#E8F9FA',
      darkBlue: '#5290A6',
      hoverBlue: '#CCEDEE',
      darkHoverBlue: '#16323D',
    },
  },
  typography: {
    ...uopxMuiTheme.typography,
    h1: {
      ...uopxMuiTheme.typography.h1,
      fontSize: uopxTheme.spacing(7.2),
      [uopxTheme.breakpoints.down('md')]: { fontSize: uopxTheme.spacing(5.6) },
      [uopxTheme.breakpoints.down('sm')]: { fontSize: uopxTheme.spacing(4.0) },
    },
    h2: {
      ...uopxMuiTheme.typography.h2,
      fontSize: uopxTheme.spacing(5.6),
      [uopxTheme.breakpoints.down('md')]: { fontSize: uopxTheme.spacing(4.0) },
      [uopxTheme.breakpoints.down('sm')]: { fontSize: uopxTheme.spacing(3.2) },
    },
    h3: {
      ...uopxMuiTheme.typography.h3,
      fontSize: uopxTheme.spacing(4.0),
      [uopxTheme.breakpoints.down('md')]: { fontSize: uopxTheme.spacing(3.2) },
      [uopxTheme.breakpoints.down('sm')]: { fontSize: uopxTheme.spacing(2.4) },
    },
    h4: {
      ...uopxMuiTheme.typography.h4,
      fontSize: uopxTheme.spacing(3.2),
      [uopxTheme.breakpoints.down('md')]: { fontSize: uopxTheme.spacing(2.4) },
    },
    h5: {
      ...uopxMuiTheme.typography.h5,
      fontSize: uopxTheme.spacing(2.4),
    },
    h6: {
      ...uopxMuiTheme.typography.h6,
      fontSize: uopxTheme.spacing(2.0),
    },
    body1: {
      ...uopxMuiTheme.typography.body1,
      fontSize: uopxTheme.spacing(3.2),
      [uopxTheme.breakpoints.down('md')]: { fontSize: uopxTheme.spacing(2.4) },
      [uopxTheme.breakpoints.down('sm')]: { fontSize: uopxTheme.spacing(2.0) },
    },
    body2: {
      ...uopxMuiTheme.typography.body2,
      fontSize: uopxTheme.spacing(2.4),
      [uopxTheme.breakpoints.down('md')]: { fontSize: uopxTheme.spacing(2.0) },
      [uopxTheme.breakpoints.down('sm')]: { fontSize: uopxTheme.spacing(1.6) },
    },
    body3: {
      ...uopxMuiTheme.typography.body3,
      fontSize: uopxTheme.spacing(2.0),
      [uopxTheme.breakpoints.down('md')]: { fontSize: uopxTheme.spacing(1.6) },
    },
    body4: {
      ...uopxMuiTheme.typography.body4,
      fontSize: uopxTheme.spacing(1.6),
    },
    body5: {
      ...uopxMuiTheme.typography.body5,
      fontSize: uopxTheme.spacing(1.4),
    },
    body6: {
      ...uopxMuiTheme.typography.body6,
      fontSize: uopxTheme.spacing(1.2),
    },
  },
  components: {
    MuiPickersCalendarHeader: {
      styleOverrides: {
        label: {
          fontSize: uopxTheme.spacing(1.6),
        },
      },
    },
    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          h4: {
            fontWeight: '400',
          },
        },
      },
    },
    MuiPickersLayout: {
      styleOverrides: {
        root: {
          display: 'block',
          position: 'relative',
        },
      },
    },
  },
});

export default theme;
