import BaseService from '@/services/wrappers/service';

export default class ContactsService extends BaseService {
  constructor(client) {
    super(client, 'contacts');
    this.getAddresses = this.getAddresses.bind(this);
    this.getAddress = this.getAddress.bind(this);
    this.putAddress = this.putAddress.bind(this);
    this.getPhones = this.getPhones.bind(this);
    this.getPhone = this.getPhone.bind(this);
    this.postPhone = this.postPhone.bind(this);
    this.putPhone = this.putPhone.bind(this);
    this.deletePhone = this.deletePhone.bind(this);
    this.getEmails = this.getEmails.bind(this);
    this.getEmail = this.getEmail.bind(this);
    this.postEmail = this.postEmail.bind(this);
    this.putEmail = this.putEmail.bind(this);
    this.deleteEmail = this.deleteEmail.bind(this);
    this.getStaffContacts = this.getStaffContacts.bind(this);
  }

  async getAddresses(personId) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/addresses?linkId=${personId}&linkType=person`,
    );
  }

  async getAddress(addressId) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/address/${addressId}`,
    );
  }

  async putAddress(addressId, body) {
    return this.client.put(`${this.baseEndpointUrl}/v1/address/${addressId}`, body);
  }

  async getPhones(personId) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/phones?linkId=${personId}&linkType=person`,
    );
  }

  async getPhone(phoneId) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/phones/${phoneId}`,
    );
  }

  async postPhone(body) {
    return this.client.post(`${this.baseEndpointUrl}/v1/phone`, body);
  }

  async putPhone(phoneId, body) {
    return this.client.put(`${this.baseEndpointUrl}/v1/phone/${phoneId}`, body);
  }

  async deletePhone(phoneId) {
    return this.client.delete(
      `${this.baseEndpointUrl}/v1/phone/${phoneId}`,
    );
  }

  async getEmails(personId) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/emails?linkId=${personId}&linkType=person`,
    );
  }

  async getEmail(emailId) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/emails/${emailId}`,
    );
  }

  async postEmail(body) {
    return this.client.post(`${this.baseEndpointUrl}/v1/email`, body);
  }

  async putEmail(emailId, body) {
    return this.client.put(`${this.baseEndpointUrl}/v1/email/${emailId}`, body);
  }

  async deleteEmail(emailId) {
    return this.client.delete(
      `${this.baseEndpointUrl}/v1/email/${emailId}`,
    );
  }

  async getStaffContacts(personId, program) {
    return this.client.get(
      `${this.baseEndpointUrl}/v2/support/${personId}?academicprogramcode=${program}`,
    );
  }
}
