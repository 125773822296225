import React from 'react';
import PropTypes from 'prop-types';
import { useClickEventTracking } from '@/hooks/useClickEventTracking';
import {
  StyledDiv, StyledArrowIcon, StyledIcon, StyledLink, StyledTextArrow,
} from '@/components/molecules/icon-tile/IconTile.styles';

function IconTile({
  target = '_blank',
  url,
  id,
  icon,
  text,
  parentComponentName = '',
  white = false,
}) {
  const trackClick = useClickEventTracking();
  const componentName = `${parentComponentName}_IconTile`;
  const styleType = white ? 'white' : 'default';

  return (
    <StyledDiv>
      <StyledLink
        href={url}
        target={target}
        onClick={() => {
          trackClick(
            componentName,
            [{ url }, { target }, { text }, { styleType }],
          );
        }}
        aria-label={text}
      >
        <StyledIcon
          id={id}
          icon={icon}
        />
        <StyledTextArrow variant="body4" display="block">
          {text}
          <StyledArrowIcon
            className="arrow"
            id={`${id}-right-arrow`}
            icon="icon-right-arrow"
          />
        </StyledTextArrow>
      </StyledLink>
    </StyledDiv>
  );
}

IconTile.propTypes = {
  id: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  target: PropTypes.string,
  white: PropTypes.bool,
  parentComponentName: PropTypes.string,
};

export default IconTile;
