import {
  DialogContentText, Dialog as MuiDialog, DialogTitle, Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import * as AtomIcon from '@/components/atoms/icon';

export const StyledDialog = styled(MuiDialog)(({ theme }) => ({
  padding: theme.spacing(3),
}));

export const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontSize: theme.spacing(2.4),
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.spacing(3),
  },
  fontWeight: '500',
  color: theme.palette.common.black,
  marginTop: theme.spacing(0),
}));

export const Icon = styled(AtomIcon.default)(({ theme }) => ({
  fill: theme.palette.custom.brandRed,
  fontSize: theme.spacing(1.5),
  marginBottom: theme.spacing(0.8),
  verticalAlign: 'middle',
}));

export const StyledDialogContent = styled(DialogContentText)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: theme.spacing(1.4),
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.spacing(1.4),
    marginLeft: theme.spacing(4.5),
  },
  fontWeight: 'bold',
  marginLeft: theme.spacing(0.0),
}));

export const StyledDialogButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  float: 'left',
  [theme.breakpoints.up('sm')]: {
    float: 'right',
  },
  '&:hover': {
    backgroundColor: theme.palette.custom.darkGrey,
    outline: 'none',
  },
  '&:focus': {
    outline: 'none',
  },
  marginTop: theme.spacing(2.0),
}));

// No error code is provided as the AEM instance does not actually use it

// No secondary content is provided as the AEM instance does not actually use it
