import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import Icon from '@/components/atoms/icon';
import StandardLink from '@/components/atoms/standard-link';

export const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.almostBlack.l30,
  padding: `${theme.spacing(1.6)} 0`,
  color: theme.palette.common.white,
}));

export const BoldTypography = styled(Typography)(() => ({
  fontWeight: 400,
}));

export const DangerousTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  '& a': {
    color: theme.palette.common.white,
  },
}));

export const LinkTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  '&:hover': {
    fontWeight: 500,
    color: theme.palette.common.white,
  },
}));

export const IconInfo = styled(Icon)(({ theme }) => ({
  fill: theme.palette.common.white,
  marginRight: theme.spacing(0.8),
}));

export const LinkButton = styled(StandardLink)(({ theme }) => ({
  color: theme.palette.common.white,
  verticalAlign: 'top',
  textDecoration: 'underline',
  textDecorationColor: theme.palette.common.white,
  '&&:focus': {
    outline: 'none',
  },
}));

export const ArrowLink = styled(StandardLink)(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: 400,
  textDecoration: 'none',
  marginLeft: theme.spacing(1),
  '& svg': {
    fill: theme.palette.common.white,
    marginLeft: theme.spacing(0.8),
    transition: 'transform 0.2s linear',
  },
  '&:hover': {
    color: theme.palette.primary.l80,
    fontWeight: 500,
    textDecoration: 'none',
    '& span': {
      color: theme.palette.primary.l80,
      fontWeight: 500,
    },
    '& svg': {
      transform: `translate(${theme.spacing(0.4)}, 0)`,
      fill: theme.palette.primary.l80,
    },
  },
  '&:active': {
    color: theme.palette.primary.l60,
    textDecoration: 'none',
    '& span': {
      color: theme.palette.primary.l60,
    },
    '& svg': {
      fill: theme.palette.primary.l60,
    },
  },
  '&:focus': {
    textDecoration: 'none',
    outline: `${theme.spacing(0.1)} solid ${theme.palette.common.white}`,
    outlineOffset: theme.spacing(0.3),
  },
}));
