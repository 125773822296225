import PropTypes from 'prop-types';
import {
  MenuContainer,
  HeaderIconLabel,
  Hamburger,
} from '@/components/molecules/hamburger-menu/HamburgerMenu.styles';
import { useClickEventTracking } from '@/hooks/useClickEventTracking';

function HamburgerMenu({
  toggleMenu, open, forwardRef, parentComponentName,
}) {
  const trackClick = useClickEventTracking();
  return (
    <MenuContainer>
      <button
        type="button"
        id="hamburger-menu-button"
        onClick={() => {
          toggleMenu();
          trackClick(
            `${parentComponentName}_MenuButton`,
            [{ open }],
          );
        }}
        aria-label={open ? 'Close Navigation Menu' : 'Open Navigation Menu'}
        aria-expanded={open ? 'true' : 'false'}
        aria-controls="MainMenuNavigation"
        data-testid="menu-button"
        ref={forwardRef}
      >
        <Hamburger open={open}>
          <span />
          <span />
        </Hamburger>
        <HeaderIconLabel aria-hidden="true">{open ? 'Close' : 'Menu' }</HeaderIconLabel>
      </button>
    </MenuContainer>
  );
}

HamburgerMenu.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default HamburgerMenu;
