import { styled } from '@mui/material/styles';
import { AppBar as MuiAppBar, Box, Toolbar as MuiToolbar } from '@mui/material';

export const AppBar = styled(MuiAppBar)(({ theme }) => ({
  boxShadow: `0 ${theme.spacing(0.1)} ${theme.spacing(0.2)} 0 rgb(0 0 0 / 20%)`,
}));

export const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.common.white,
  zIndex: 3,
  [theme.breakpoints.up('xs')]: {
    minHeight: theme.spacing(8.2),
  },
}));

export const Banner = styled(Box)(({ theme }) => ({
  display: 'block',
  height: 27,
  backgroundColor: theme.palette.almostBlack.l80,
  width: '100%',
  zIndex: 3,
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));
