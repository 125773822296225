export const getItem = (key: string) => (
  typeof window !== 'undefined' ? window?.sessionStorage?.getItem(key) : undefined
);

export const setItem = (key: string, value: any) => {
  if (typeof window !== 'undefined') window?.sessionStorage?.setItem(key, value);
};

export const removeItem = (key: string) => {
  if (typeof window !== 'undefined') window?.sessionStorage?.removeItem(key);
};
