import Head from 'next/head';
import Footer from '@/components/organisms/footer';
import Header from '@/components/organisms/header/v1';
import dynamic from 'next/dynamic';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import theme from '@/helpers/theme';
import '@uopx/mui-style/globals.css';

const Chatbot = dynamic(() => import('@/components/organisms/chatbot'), { ssr: false });
const FeedbackContainer = dynamic(() => import('@/components/organisms/feedback-container'));
const MockStripe = dynamic(() => import('@/components/molecules/mock-stripe'), { ssr: false });
const muiTheme = createTheme(theme);
function Layout({
  children,
  title,
}) {
  const defaultTitle = 'Home';
  return (
    <ThemeProvider theme={muiTheme}>
      <MockStripe />
      <Head>
        <title>{title || defaultTitle}</title>
      </Head>
      <Header />
      <FeedbackContainer />
      <Chatbot />
      <main role="main">
        {children}
      </main>
      <Footer />
    </ThemeProvider>
  );
}

export default Layout;
