import { styled } from '@mui/material/styles';
import StandardLink from '@/components/atoms/standard-link';

/* eslint-disable-next-line import/prefer-default-export */
export const StyledLink = styled(StandardLink)(({ theme }) => ({
  display: 'flex',
  fontWeight: 'inherit',
  color: 'inherit',
  textDecoration: 'none',
  ':focus, :hover': {
    textDecoration: 'underline',
    textDecorationColor: theme.palette.custom.brandRed,
  },
}));
