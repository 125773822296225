import BaseService from '@/services/wrappers/service';
import type { Client } from '@/services';

export default class MockService extends BaseService {
  constructor(client: Client) {
    super(client, 'mock', '/api');

    this.getMockScenarios = this.getMockScenarios.bind(this);
  }

  async getMockScenarios() {
    return this.client.get(
      `${this.baseEndpointUrl}/api/index/persons/ids.json`,
    );
  }
}
