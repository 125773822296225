import React from 'react';
import PropTypes from 'prop-types';
import { StyledTypography, StyledLink, LogoIcon } from '@/components/molecules/centered-logo/CenteredLogo.styles';
import { useClickEventTracking } from '@/hooks/useClickEventTracking';

function CenteredLogo({
  id,
  parentId,
  version = 'header',
  href,
  text = 'MyPhoenix',
  parentComponentName,
}) {
  const trackClick = useClickEventTracking();

  return (
    <StyledLink
      href={href}
      version={version}
      aria-label="My Phoenix"
      onClick={() => {
        trackClick(
          `${parentComponentName}_LogoLink`,
          [{ url: href }, { text }, { id }],
        );
      }}
    >
      <LogoIcon id={`${parentId}-${id}`} icon="icon-phoenix-logo" />
      <StyledTypography
        variant="body3"
        version={version}
      >
        {text}
      </StyledTypography>
    </StyledLink>
  );
}

CenteredLogo.propTypes = {
  id: PropTypes.string.isRequired,
  parentId: PropTypes.string.isRequired,
  version: PropTypes.string,
  text: PropTypes.string,
};

export default CenteredLogo;
