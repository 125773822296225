import BaseService from '@/services/wrappers/service';

export default class DocumentService extends BaseService {
  constructor(client) {
    super(client, 'document');
    this.getSubmittedApplicationHTML = this.getSubmittedApplicationHTML.bind(this);
  }

  //   /**
  //    * Returns submitted application as HTML/JS/CSS.
  //    *
  //    * @returns Promise<*>
  //    */
  async getSubmittedApplicationHTML(applicationId, packetName = 'BECOME_POST_SIGN_PACKET') {
    return this.client.get(`${this.baseEndpointUrl}/v1/formOrPacket?applicationId=${applicationId}&formOrPacketName=${packetName}`);
  }
}
