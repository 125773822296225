import configs from '@/helpers/config';
import BaseService from '@/services/wrappers/service';

const { salesForce: { baseUrl, ownerId, recordTypeId } = {} } = configs;

export default class FeedbackSurveyService extends BaseService {
  constructor(client) {
    super(client);
    this.baseUrl = baseUrl;
    this.ownerId = ownerId;
    this.recordTypeId = recordTypeId;
    this.postFeedbackSurvey = this.postFeedbackSurvey.bind(this);
  }

  //   /**
  //    * Returns submitted application as HTML/JS/CSS.
  //    *
  //    * @returns Promise<`*>
  //    */
  async postFeedbackSurvey(pLoad, endpoint) {
    const payload = {
      ...(pLoad || {}),
      ownerId: this.ownerId,
      recordTypeId: this.recordTypeId,
    };

    return this.client.post(this.baseUrl + endpoint, payload);
  }
}
