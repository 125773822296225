import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import StandardLink from '@/components/atoms/standard-link';

export const StyledAvatarContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'spaceEvenly',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: theme.palette.text.primary,
    },
    [theme.breakpoints.down('sm')]: {
      top: `${theme.spacing(0.5)}`,
    },
  },
}));

export const StyledAvatarLink = styled(StandardLink)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.primary,
  textDecoration: 'none',
  '&:hover, &:focus, &:hover > span, &:focus > span': {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  [theme.breakpoints.down('sm')]: {
    display: 'inline-block',
    '&:hover, &:focus, &:hover > span, &:focus > span': {
      color: theme.palette.text.primary,
    },
  },
}));

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: '50px',
  height: '50px',
  fontSize: '24px',
  fontWeight: 900,
  backgroundColor: theme.palette.custom.darkGrey,
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const StyledAvatarNameContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: `${theme.spacing(1)}`,
  maxWidth: theme.spacing(16),
  [theme.breakpoints.up('md')]: {
    marginLeft: `${theme.spacing(1.6)}`,
    maxWidth: theme.spacing(26),
  },
}));

export const StyledAvatarName = styled('span')(({ theme }) => ({
  position: 'relative',
  fontWeight: 900,
  fontSize: `${theme.spacing(1.5)}`,
  flexDirection: 'column',
  justifyContent: 'center',
  marginBottom: `${theme.spacing(0.2)}`,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    left: `${theme.spacing(0.6)}`,
  },
  [theme.breakpoints.down('sm')]: {
    fontWeight: 400,
    fontSize: theme.spacing(1.2),
    padding: `0 ${theme.spacing(0.7)} 0 0`,
  },
}));

export const StyledIrnContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: `${theme.spacing(3.4)}`,
  left: `${theme.spacing(6.6)}`,
  width: `${theme.spacing(22.0)}`,
  '& svg': {
    cursor: 'pointer',
    height: `${theme.spacing(1.0)}`,
    width: `${theme.spacing(1.0)}`,
  },
  [theme.breakpoints.down('sm')]: {
    position: 'relative',
    display: 'inline-block',
    left: `${theme.spacing(0.0)}`,
    top: `-${theme.spacing(0.0)}`,
  },
}));

export const StyledIrnLetters = styled('span')(({ theme }) => ({
  fontSize: `${theme.spacing(1.1)}`,
  color: theme.palette.text.primary,
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const StyledIrnText = styled('span')(({ theme }) => ({
  fontSize: `${theme.spacing(1.1)}`,
  width: `${theme.spacing(22.0)}`,
  color: theme.palette.text.primary,
  [theme.breakpoints.down('sm')]: {
    color: theme.palette.almostBlack.l30,
  },
}));

// @ts-ignore
export const StyledIrnCopiedText = styled('span', { shouldForwardProp: (prop) => prop !== 'shouldShow' })(({ theme, shouldShow }) => ({
  color: theme.palette.text.primary,
  fontSize: `${theme.spacing(1)}`,
  transition: 'opacity 0.5s',
  transitionDelay: '0s, 2s',
  opacity: shouldShow ? 1 : 0,
  display: shouldShow ? 'inherit' : 'none',
}));
