import BaseService from '@/services/wrappers/service';

export default class ApplicationService extends BaseService {
  constructor(client) {
    super(client, 'applications');
    this.getApplicationInfoByApplicationId = this.getApplicationInfoByApplicationId.bind(this);
  }

  async getApplicationInfoByApplicationId(applicationId) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/applications/${applicationId}`,
    );
  }
}
