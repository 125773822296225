import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import Icon from '@/components/atoms/icon';
import StandardLink from '@/components/atoms/standard-link';

export const StyledDiv = styled('div')(({ theme }) => ({
  width: theme.spacing(14.5),
  height: theme.spacing(10),
  [theme.breakpoints.between('sm', 'md')]: {
    width: theme.spacing(16),
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'center',
  borderRadius: theme.spacing(0.6),
  '&:hover, &:hover .arrow': {
    left: theme.spacing(2.4),
    transition: 'all 0.4s ease-out',
  },
}));

export const StyledLink = styled(StandardLink)(({ theme }) => ({
  textAlign: 'center',
  textDecoration: 'none',
  '&:focus': {
    outline: `${theme.spacing(0.1)} solid ${theme.palette.almostBlack.main}`,
    outlineOffset: theme.spacing(1),
  },
}));

export const StyledTextArrow = styled(Typography)(({ theme }) => ({
  fontWeight: '470',
  color: theme.palette.common.black,
}));

export const StyledArrowIcon = styled(Icon)(({ theme }) => ({
  position: 'relative',
  left: theme.spacing(1.0),
  height: theme.spacing(1.8),
  width: theme.spacing(2),
  top: theme.spacing(0.4),
  fill: theme.palette.primary.main,
}));

export const StyledIcon = styled(Icon)(({ theme }) => ({
  height: theme.spacing(3.6),
  width: theme.spacing(3.6),
  marginBottom: theme.spacing(0.4),
}));
