import { SessionUser } from 'next-auth';

export const TOKEN_STORAGE_KEY = 'MY-NEXT-TOKEN';
export const CUSTOM_TOKEN_STORAGE_KEY = 'CUSTOM-MY-NEXT-TOKEN';
export const PERSON_ID_STORAGE_KEY = 'MY-NEXT-PERSON-ID';
export const EMAIL_ID_STORAGE_KEY = 'MY-NEXT-EMAIL-ID';
export const SIGN_OUT_CALLBACK = 'signOut';
export const TOKEN_REFRESH_ERROR = 'RefreshAccessTokenError';
// https://bitbucket.org/codeuopx/myfinance-nextjs-app/src/master/src/pages/api/logout.js
export const COOKIES_TO_CLEAR = [
  'portal-exp=;Path=/;Domain=.phoenix.edu;Expires=Thu, 01-Jan-1970 00:00:00 GMT;Max-Age=0;Secure;SameSite=None',
  'login-token=;Path=/;Expires=Thu, 01-Jan-1970 00:00:00 GMT;Max-Age=0;HttpOnly;Secure',
  // May no longer be needed after full changeover
  'MyPhoenix.PersonId=;Path=/;Domain=.phoenix.edu;Expires=Thu, 01-Jan-1970 00:00:00 GMT;Max-Age=0;Secure;SameSite=None',
  'APOLLOASSERTION=;Path=/;Domain=.phoenix.edu;Expires=Thu, 01-Jan-1970 00:00:00 GMT;Max-Age=0;Secure;SameSite=None',
  'APOLLOSSODCOOKIE=;Path=/;Domain=.phoenix.edu;Expires=Thu, 01-Jan-1970 00:00:00 GMT;Max-Age=0;Secure;SameSite=None',
  'APOLLOSSOZCOOKIE=;Path=/;Domain=.phoenix.edu;Expires=Thu, 01-Jan-1970 00:00:00 GMT;Max-Age=0;Secure;SameSite=None',
];
export const NULL_USER: SessionUser = {
  id: null,
};
