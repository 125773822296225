import { getEmailIdFromSessionStorage } from '@/helpers/auth';
import { dispatchToGTM, USER_IDENTIFIED_ENRICHMENT_EVENT_NAME } from '@/myphoenix/utils/event-functions';
import {
  useGetPersonByIdQuery,
  useGetOfficialNameQuery,
} from '@/store/queries/person';
import useGetPrimaryOrDefaultEmailByPersonIdHook from '@/store/hooks/useGetPrimaryOrDefaultEmailByPersonIdHook';
import { memo } from 'react';

export function GoogleDataLayer({ personId }: { personId: string }): null {
  const { data: personData,
    isLoading: isPersonDataLoading } = useGetPersonByIdQuery({ personId }, { skip: !personId });
  const { data: personNames,
    isLoading: isPersonNamesLoading } = useGetOfficialNameQuery({ personId }, { skip: !personId });
  const {
    data: primaryEmail,
    isFetching: isFetchingPrimaryEmail,
  } = useGetPrimaryOrDefaultEmailByPersonIdHook(
    personId,
  );

  if (!isPersonDataLoading && !isPersonNamesLoading && !isFetchingPrimaryEmail) {
    const [profileId] = personData?.externalSystemIds?.PROFILE_ID || [''];

    dispatchToGTM(USER_IDENTIFIED_ENRICHMENT_EVENT_NAME, {
      user: {
        personId,
        profileId,
        irn: personData?.issuerId || '',
        username: personData?.username || '',
        email: primaryEmail?.emailAddress || getEmailIdFromSessionStorage(),
        person: {
          firstName: personNames?.firstName || '',
          lastName: personNames?.lastName || '',
          role: [personData?.type || ''],
        },
      },
    });
  }

  return null;
}

export default memo(GoogleDataLayer);
