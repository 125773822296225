import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import sessionReducer from './slices/session';
import { baseApi, baseApi2, nonPersistedBaseApi } from './queries';
import { mockScenariosApi } from './queries/mock-scenarios';
import { builderApi } from './queries/builder.io';
import { segmentApi } from './queries/segment';

const combinedReducers = combineReducers({
  session: sessionReducer,
  [baseApi.reducerPath]: baseApi.reducer,
  [baseApi2.reducerPath]: baseApi2.reducer,
  [nonPersistedBaseApi.reducerPath]: nonPersistedBaseApi.reducer,
  [mockScenariosApi.reducerPath]: mockScenariosApi.reducer,
  [builderApi.reducerPath]: builderApi.reducer,
  [segmentApi.reducerPath]: segmentApi.reducer,
});

export type RootState = ReturnType<typeof combinedReducers>;

export const reducers = (state: RootState, action: AnyAction) => {
  if (action.type === 'session/clearState') {
    // state = undefined; // {} as RootState;
    return combinedReducers(undefined, action);
  }
  return combinedReducers(state, action);
};

export default reducers;
