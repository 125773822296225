import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import Icon from '@/components/atoms/icon';
import StandardLink from '@/components/atoms/standard-link';

export const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'version',
})(({ theme, version }) => ({
  color: theme.palette.text.primary,
  fontWeight: 700,
  position: 'relative',
  bottom: theme.spacing(1.1), // was .7
  paddingLeft: theme.spacing(0.8),
  ...(version === 'header' && {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  }),
}));

export const StyledLink = styled(StandardLink, {
  shouldForwardProp: (prop) => prop !== 'version',
})(({ theme, version }) => ({
  textDecoration: 'none',
  ...(version === 'footer' && {
    '&:hover': {
      borderBottom: `0.3rem solid ${theme.palette.primary.main}`,
      paddingBottom: theme.spacing(0.2),
    },
  }),
  ...(version === 'header' && {
    '&:hover span': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  }),
}));

export const LogoIcon = styled(Icon)(({ theme }) => ({
  fill: theme.palette.primary.main,
}));
