import { ProgramTemplate } from '@/types/api/programs';
import { Status } from '@/types/api/student/studentStatus';

export interface ExternalSystemIds {
  programCodeVersion: string[]
}

export interface RawProgram {
  id: string,
  primary: string,
  startDate: string,
  anticipatedStartDate: string,
  enrollmentAgreementSignDate: string,
  creditsCompleted: number,
  creditsTransferred: number,
  creditsWaived: number,
  creditsEarned: number,
  creditsRemaining: number,
  creditsAssessed?: number,
  lastActivityDate: string,
  startingAcademicYear: string,
  externalSystemIds: ExternalSystemIds,
  programCodeVersion?: string[],
  statuses: Status[],
  programCompletionDeadlineDate?: string,
  programGpa: number,
  programCompletionDate?: string,
  enrollmentAgreementCampus?: string;
  olda?: string;
  personId?: string;
}

export interface TransformedProgram {
  id?: string;
  code?: string;
  creditsSummary?: {
    [key: string]: number;
  };
  version?: string;
  isPrimary?: boolean;
  lastActivityDate?: string;
  programCompletionDeadlineDate?: string;
  startDate?: string;
  programCompletionDate?: string;
  programGpa?: number;
  statuses?: Status[];
}

export interface FormattedProgramData extends TransformedProgram {
  type?: string;
  externalProgramType?: string;
  qualificationLevel?: string;
  name?: string;
  description?: string;
  cbeProgram?: string;
  cbeDaProgram?: string;
}

export const getTransformedProgramsData = (programs: RawProgram[]): TransformedProgram[] => {
  let primaryId = '';
  const transformedPrograms: TransformedProgram[] = programs.map((program) => {
    const transformedProgram: TransformedProgram = {
      id: '',
      code: '',
      creditsSummary: {},
      version: '',
      isPrimary: false,
      lastActivityDate: '',
      programCompletionDeadlineDate: '',
      startDate: '',
      programCompletionDate: '',
      programGpa: 0,
      statuses: [],
    };

    const programData = program.externalSystemIds
    || { programCodeVersion: [...program.programCodeVersion] };
    if (programData.programCodeVersion.length) {
      const codeVersion = programData.programCodeVersion[0].split(':');
      [transformedProgram.code, transformedProgram.version] = codeVersion;
    }
    transformedProgram.id = program.id || '';
    transformedProgram.isPrimary = program.primary === 'true';
    if (transformedProgram.isPrimary) {
      primaryId = transformedProgram.id;
    }
    transformedProgram.statuses = program.statuses || [];
    transformedProgram.lastActivityDate = program.lastActivityDate || '';
    transformedProgram.programCompletionDeadlineDate = program.programCompletionDeadlineDate || '';
    transformedProgram.startDate = program.startDate || '';
    transformedProgram.programCompletionDate = program.programCompletionDate || '';
    transformedProgram.programGpa = program.programGpa || 0;
    transformedProgram.creditsSummary = {
      assessed: program.creditsAssessed || 0,
      completed: program.creditsCompleted || 0,
      earned: program.creditsEarned || 0,
      remaining: program.creditsRemaining || 0,
      transferred: program.creditsTransferred || 0,
      waived: program.creditsWaived || 0,
    };
    return transformedProgram;
  });

  (transformedPrograms as any).primaryProgramId = primaryId;
  return transformedPrograms;
};

export const getFormattedProgramData = (
  transformedAllProgramsData: TransformedProgram[],
  programTemplates: ProgramTemplate[],
): { primaryProgramId: string, programsListArray: FormattedProgramData[] } => {
  const primaryProgramId = transformedAllProgramsData.find((program) => program.isPrimary)?.id;
  const programsListArray = transformedAllProgramsData.map((program) => {
    const matchingTemplate = programTemplates?.find(
      (template) => !!template && !!program
          && template.programId === program.code && template.version === program.version,
    ) || {};
    return matchingTemplate ? {
      ...program,
      type: matchingTemplate.programType || '',
      externalProgramType: matchingTemplate.externalProgramType || '',
      qualificationLevel: matchingTemplate.programLevel || '',
      name: matchingTemplate.displayName || '',
      description: matchingTemplate.textDescription || '',
      cbeProgram: matchingTemplate.cbeProgram || '',
      cbeDaProgram: matchingTemplate.cbeDaProgram || '',
    } : program;
  });
  return { primaryProgramId, programsListArray };
};
