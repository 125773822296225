const analytics = {
  Page: {
    Name: 'Home',
  },
  User: {
    MilitaryType: 'False',
    creditsTransferred: 25,
    startDate: new Date(),
    programCompletionDate: new Date(),
    programLevel: 'Graduate Level',
    studentStatus: {
      status: 'F',
    },
    nearGrad: 'True',
    outOfAttendance: '', // Otherwise, 'x days'
  },
};

export default analytics;
