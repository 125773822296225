import { styled } from '@mui/material/styles';
import {
  Accordion,
  AccordionDetails,
  Typography,
  AccordionSummary,
} from '@mui/material';
import Icon from '@/components/atoms/icon';
import { withTransientProps } from '@/helpers/with-transient-props';

export const StyledItem = styled(
  'li',
  withTransientProps,
)(({ theme, $styleType }) => ({
  padding: `${theme.spacing(0.2)} 0`,
  paddingBottom: $styleType === 'table' ? theme.spacing(2) : 0,
  '& a': {
    color:
      $styleType === 'white'
        ? theme.palette.background.paper
        : theme.palette.text.primary,
    textDecoration: 'underline',
    '&:hover, &:focus': {
      textDecoration: 'none',
      borderBottom: `${theme.spacing(0.3)} solid ${theme.palette.primary.main}`,
    },
    '&:focus': {
      outline: `${theme.spacing(0.1)} solid ${theme.palette.text.primary}`,
      outlineOffset: theme.spacing(0.3),
    },
  },
  '& body4': {
    fontWeight: '600',
    color: theme.palette.text.disabled,
    '& button > .MuiButton-label': {
      textDecoration: 'underline',
      fontWeight: '600',
      '&:hover, &:focus': {
        textDecoration: 'none',
        borderBottom: `${theme.spacing(0.3)} solid ${
          theme.palette.primary.main
        }`,
      },
    },
  },
}));

export const StyledAccordion = styled(
  Accordion,
  withTransientProps,
)(({ theme, $styleType }) => ({
  backgroundColor: 'transparent',
  color:
    $styleType === 'white'
      ? theme.palette.background.paper
      : theme.palette.text.primary,
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: 0,
  },
  ...($styleType === 'table' && {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  }),
}));

export const Summary = styled(AccordionSummary)(({ theme }) => ({
  minHeight: theme.spacing(1),
  padding: 0,
  '&.Mui-expanded': {
    minHeight: theme.spacing(1),
  },
  '&.Mui-focused': {
    backgroundColor: 'transparent',
    outline: `${theme.spacing(0.1)} solid ${theme.palette.text.primary}`,
  },
  '& .MuiAccordionSummary-content': {
    margin: `${theme.spacing(1.5)} 0`,
  },
  '& .MuiIconButton-edgeEnd': {
    marginRight: theme.spacing(-1),
  },
}));

export const StyledExpandIcon = styled(
  Icon,
  withTransientProps,
)(({ theme, $styleType }) => ({
  fill:
    $styleType === 'white'
      ? theme.palette.background.paper
      : theme.palette.primary.main,
}));

export const SummaryHeader = styled(
  Typography,
  withTransientProps,
)(({ $styleType, theme, $expanded }) => ({
  fontWeight: '500',
  ...($styleType === 'table' && {
    fontWeight: '900',
    fontSize: '1.8rem',
    color: $expanded
      ? theme.palette.primary.main
      : theme.palette.almostBlack.main,
  }),
}));

export const StyledDetails = styled(AccordionDetails)(({ theme }) => ({
  marginLeft: theme.spacing(0.3),
  marginTop: theme.spacing(-0.5),
  padding: 0,
}));

export const DetailsContent = styled(Typography)(() => ({
  '& ul': {
    margin: 0,
    paddingInlineStart: 0,
    listStyle: 'none',
    '& li': {
      listStyle: 'none',
    },
  },
}));
