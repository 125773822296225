import BaseService from '@/services/wrappers/service';

export default class ProgramsService extends BaseService {
  constructor(client) {
    super(client, 'programs');
    this.getTemplateByProgramIdAndVersion = this.getTemplateByProgramIdAndVersion.bind(this);
    this.getProgramInfoByProgramOfferingId = this.getProgramInfoByProgramOfferingId.bind(this);
  }

  async getTemplateByProgramIdAndVersion(programId, version) {
    return this.client.get(
      `${this.baseEndpointUrl}/v3/templates?$filter=programId eq '${programId}' and version eq '${version}'`,
    );
  }

  async getProgramInfoByProgramOfferingId(programOfferingId) {
    return this.client.get(
      `${this.baseEndpointUrl}/v3/programEligibility/${programOfferingId}`,
    );
  }
}
