import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const MenuContainer = styled(Box)(({ theme }) => ({
  width: theme.spacing(4.5),
  height: 'inherit',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  alignSelf: 'start',
  paddingTop: theme.spacing(0.3),

  '& button': {
    cursor: 'pointer',
    zIndex: 100,
    border: 'none',
    background: 'none',
  },
}));

export const HeaderIconLabel = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  fontSize: theme.spacing(1.4),
  lineHeight: theme.spacing(1.6),
  textDecoration: 'none',
  color: theme.palette.text.primary,
}));

export const Hamburger = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  ...(!open && {
    position: 'relative',
    alignSelf: 'center',
    padding: 0,
    width: '3.5rem',
    height: '2.3rem',
    top: theme.spacing(-0.6),

    '& span, &:before, &:after': {
      display: 'block',
      position: 'absolute',
      borderRadius: '0.3rem',
      width: '100%',
      height: '0.4rem',
      background: theme.palette.common.black,
    },

    '& span': {
      top: '50%',
      transition: 'all 0.3s ease-in-out',
    },

    '&:before, &:after': {
      content: '\'\'',
      transition: 'all 0.3s ease-in-out 0.2s',
    },

    '&:before': {
      top: '0.3rem',
    },

    '&:after': {
      bottom: '-0.1rem',
    },
  }),
  ...(open && {

    position: 'relative',
    alignSelf: 'center',
    padding: 0,
    width: '3.5rem',
    height: '2.3rem',
    top: theme.spacing(-0.6),

    '& span, &:before, &:after': {
      display: 'block',
      position: 'absolute',
      borderRadius: '0.3rem',
      left: 0,
      width: '100%',
      height: '0.4rem',
      background: theme.palette.common.black,
    },

    '& span': {
      top: '50%',
      transition: 'all 0.3s ease-in-out',
    },

    '& span:first-of-type': {
      transform: 'rotate(45deg)',
    },

    '& span:last-of-type': {
      transform: 'rotate(-45deg)',
    },

    '&:before, &:after': {
      opacity: (0),
      transition: 'all 0.3s ease-in-out 0.2s',
    },

    '&:before': {
      top: '50%',
    },

    '&:after': {
      bottom: '50%',
    },
  }),
}));
