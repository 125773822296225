import BaseService from '@/services/wrappers/service';

export default class ProgramsServiceV1 extends BaseService {
  constructor(client) {
    super(client, 'programs');
    this.getLoaReturnDatesByIrn = this.getLoaReturnDatesByIrn.bind(this);
  }

  async getLoaReturnDatesByIrn(irn, loaStartdate) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/loaReturnDates?$filter=irn eq '${irn}' and loaStartDate eq '${loaStartdate}'`,
    );
  }
}
