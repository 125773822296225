import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import StandardLink from '@/components/atoms/standard-link';

export const StyledHeader = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'footer',
})(({ theme, footer }) => ({
  color: footer ? theme.palette.common.black : theme.palette.primary.main,
  textTransform: 'uppercase',
  fontWeight: 700,
  lineHeight: theme.spacing(1.5),
  margin: 0,
}));

export const StyledList = styled('ul')(({ theme }) => ({
  padding: `${theme.spacing(1.5)} 0 0 0`,
  margin: 0,
  lineHeight: 1,
}));

export const StyledItem = styled('li')(({ theme }) => ({
  listStyle: 'none',
  paddingTop: theme.spacing(1.5),
  '&:first-of-type': {
    paddingTop: '0',
  },
}));

export const StyledLink = styled(StandardLink, {
  shouldForwardProp: (prop) => prop !== 'footer',
})(({ theme, footer }) => ({
  fontWeight: footer ? 400 : 500,
  lineHeight: 1,
  paddingTop: theme.spacing(1.5),
  color: theme.palette.almostBlack.main,
  '&:hover': {
    color: footer ? '' : theme.palette.primary.main,
    textDecoration: 'underline',
    textDecorationColor: theme.palette.primary.main,
  },
  '&:first-of-type': {
    paddingTop: '0',
  },
}));
