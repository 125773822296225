import { TrackingHook, useTracking } from 'react-tracking';
import { useCallback } from 'react';
import { createEventObject } from '@/myphoenix/utils/event-functions';

export const useDisplayEventTrackingOnMount = (
  componentName: string,
  properties?: { [key: string]: any }[],
) => {
  useTracking(createEventObject(
    componentName,
    properties,
  ), { dispatchOnMount: true });
};

export const useDisplayEventTracking = () => {
  const { trackEvent }: TrackingHook<{
    trackingMethod: string,
    componentName: string,
    properties?: any }> = useTracking({ trackingMethod: 'trackDisplay' });
  const trackDisplay = useCallback((
    componentName: string,
    properties?: { [key: string]: any }[],
  ) => {
    trackEvent(createEventObject(
      componentName,
      properties,
    ));
  }, [trackEvent]);
  return trackDisplay;
};
