import BaseService from '@/services/wrappers/service';

export default class CommunicationService extends BaseService {
  constructor(client) {
    super(client, 'communication');
    this.getNotificationsByPersonId = this.getNotificationsByPersonId.bind(this);
    this.getAlertsByPersonId = this.getAlertsByPersonId.bind(this);
    this.putNotificationAsReadOrUnread = this.putNotificationAsReadOrUnread.bind(this);
  }

  async getNotificationsByPersonId(personId) {
    return this.client.get(
      `${this.baseEndpointUrl}/v2/notifications/${personId}`,
    );
  }

  async getAlertsByPersonId(personId) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/alerts/${personId}`,
    );
  }

  async putNotificationAsReadOrUnread(personId, notificationId, read = true) {
    const requestBody = {
      read,
      states: {
        status: {
          action: read ? 'COMPLETED' : 'ASSIGNED',
        },
      },
    };

    return this.client.put(
      `${this.baseEndpointUrl}/v2/notifications/${personId}/${notificationId}`,
      requestBody,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }
}
