import { getBBRootUrl, getBBClassUrl, openBlackboard } from '@/myphoenix/utils/blackboard-functions';
import { getBrightspaceBaseUrl, getBrightspaceClassUrl, openBrightspace } from '@/myphoenix/utils/brightspace-functions';
import { toTitleCase } from '@/helpers/string-utils';
import configs from '@/helpers/config';

const { myphxPublic } = configs;

export const getClassUrl = (isCbedaProgram: boolean, courseId?: string) => {
  if (isCbedaProgram) {
    return courseId ? getBrightspaceClassUrl() : getBrightspaceBaseUrl();
  }

  return courseId ? getBBClassUrl(courseId) : getBBRootUrl();
};

export const BLACKBOARD = 'BLACKBOARD';
export const BRIGHTSPACE = 'BRIGHTSPACE';

type ClassRoomKey = 'BLACKBOARD' | 'BRIGHTSPACE';

const CLASSROOM_MAP = {
  [BLACKBOARD]: {
    baseUrl: getBBRootUrl(),
    classUrl: (props: { [item: string]: any }) => getBBClassUrl(props.sectionId),
    openClassUrl: (props: { [item: string]: any }) => openBlackboard(props.sectionId),
  },
  [BRIGHTSPACE]: {
    baseUrl: getBrightspaceBaseUrl(),
    classUrl: () => getBrightspaceClassUrl(),
    openClassUrl: () => openBrightspace(),
  },
};

export const getClassroomKey = (props: { isCBEDA: boolean }) => {
  const { isCBEDA } = props;
  if (isCBEDA) {
    return BRIGHTSPACE;
  }
  return BLACKBOARD;
};

export const getBaseClassroomUrl = (key: ClassRoomKey) => CLASSROOM_MAP[`${key}`].baseUrl;

export const getClassroomClassUrl = (
  key: ClassRoomKey,
  props: { [item: string]: any },
) => CLASSROOM_MAP[key].classUrl(props);

export const openClassUrl = (
  key: ClassRoomKey,
  props: { [item: string]: any },
) => CLASSROOM_MAP[key].openClassUrl(props);

export const openClass = (isCBEDA: boolean, courseId?: string) => openClassUrl(
  getClassroomKey({ isCBEDA }),
  { sectionId: courseId },
);

export const stringHasBlackboard = (str: string) => str.toLowerCase()
  .includes(BLACKBOARD.toLowerCase());
export const replaceBlackboardWithClassRoomValueIfNeeded = (isCBEDA: any, str: string) => {
  if (isCBEDA) {
    return str.replace(toTitleCase(BLACKBOARD), toTitleCase(BRIGHTSPACE));
  }
  return str;
};

export const goToClassInfoLink = `${myphxPublic.baseUrl}${myphxPublic.goToClassInfo}`;
