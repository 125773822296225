import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BadgeContainer,
  NotificationBadge,
  Bell,
  BellTop,
  BellBottom,
  NotificationBellLabel,
  NotificationBellLink,
} from '@/components/atoms/notification-bell/NotificationBell.styles';
import { useClickEventTracking } from '@/hooks/useClickEventTracking';

function NotificationBell({
  badgeContent = 0,
  href = '/message-center.html',
  parentComponentName,
}) {
  const trackClick = useClickEventTracking();
  const componentName = `${parentComponentName}_NotificationBell`;
  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    setAnimate((badgeContent || 0) > 0);
    setTimeout(() => {
      setAnimate(false);
    }, 1000);
  }, [badgeContent]);
  const ariaLabelCondition = badgeContent ? `${badgeContent} New notifications available.` : 'No new notifications.';
  return (
    <NotificationBellLink
      id="notifications-link"
      href={href}
      target="_self"
      aria-label={`Notifications link. ${ariaLabelCondition}`}
      aria-live="polite"
      tabIndex={0}
      onClick={() => trackClick(
        componentName,
        [{ badgeContent }, { url: href }, { target: '_self' }],
      )}
    >
      <BadgeContainer>
        <NotificationBadge
          overlap="circular"
          color="primary"
          badgeContent={badgeContent}
          max={10}
        >
          <Bell>
            <BellTop animate={animate} />
            <BellBottom animate={animate} />
          </Bell>
        </NotificationBadge>
        <NotificationBellLabel>
          Notifications
        </NotificationBellLabel>
      </BadgeContainer>
    </NotificationBellLink>
  );
}

export default NotificationBell;

NotificationBell.propTypes = {
  badgeContent: PropTypes.number,
  href: PropTypes.string,
};
