import type { FetchClientType } from '@/services/wrappers/fetch-types';
import BaseService from '@/services/wrappers/service';
import { Person,
  Name,
  AvatarImage,
  Information,
  EventCounter,
  Military } from '@/types/api/persons';

// NOTE: This can be extended in the case you need to extend to a v2, v3, etc.
export default class PersonsService extends BaseService {
  constructor(client: FetchClientType) {
    super(client, 'persons');
    this.getPersonByPersonId = this.getPersonByPersonId.bind(this);
    this.getNamesByPersonId = this.getNamesByPersonId.bind(this);
    this.getAvatarByPersonId = this.getAvatarByPersonId.bind(this);
    this.getDemographicsByPersonId = this.getDemographicsByPersonId.bind(this);
    this.getEventsByPersonId = this.getEventsByPersonId.bind(this);
    this.getUserAttributesByPersonId = this.getUserAttributesByPersonId.bind(this);
    this.getMilitaryStatus = this.getMilitaryStatus.bind(this);
  }

  async getPersonByPersonId(personId: string): Promise<{ data: Person }> {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/person/${personId}`,
    );
  }

  async getNamesByPersonId(personId: string): Promise<{ data: Array<Name> }> {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/person/${personId}/names`,
    );
  }

  async getAvatarByPersonId(personId: string): Promise<{ data: AvatarImage }> {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/person/${personId}/avatar/image`,
    );
  }

  async getDemographicsByPersonId(personId: string): Promise<{ data: Information }> {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/person/${personId}/information`,
    );
  }

  async getEventsByPersonId(personId: string): Promise<{ data: EventCounter }> {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/person/${personId}/events-count?context=message,logon`,
    // Can be adjusted to have context = logon or message independently
    );
  }

  // TODO: persons swagger is misconfigured for this response
  async getUserAttributesByPersonId(personId: string) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/person/attributes/${personId}`,
    );
  }

  async getMilitaryStatus(personId: string): Promise<{ data: Military }> {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/person/${personId}/military`,
    );
  }
}
