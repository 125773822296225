import { baseApi, isQueryFnErrorResponse } from '@/store/queries';
import type { Services } from '@/services';
import type { Course } from '@/types/courseInfoData';

export const skillApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getSkillsForCourse: build.query({
      async queryFn(
        { templateId, token }:
        { templateId: string, token?: string },
        { extra },
      ) {
        const { LearningService } = extra as Services;
        try {
          const results = await LearningService.setToken(token)
            .setServerSideBaseUrl().getSkillsForCourse(templateId);
          return { data: results?.data };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
    }),
    getAllSkillsForCourses: build.query({
      async queryFn(
        { courses, token }:
        { courses: Course[], token?: string },
        { extra },
      ) {
        const { LearningService } = extra as Services;
        try {
          const promises = courses?.map((course) => (
            LearningService.setToken(token)
              .setServerSideBaseUrl()
              .getSkillsForCourse(course?.templateCode)
          ));

          const results = await Promise.all(promises);
          const data = results.map((result) => result.data);
          return { data };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
    }),
  }),
});

export const {
  useGetSkillsForCourseQuery,
  useGetAllSkillsForCoursesQuery,
} = skillApi;
