import { baseApi, isQueryFnErrorResponse } from '@/store/queries';
import type { Services } from '@/services';

export type DocumentServiceDocType = {
  irn: string;
  docCode: string;
  fileName: string;
  sourceSystem: string;
  fileBytes: string;
};
export const documentsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    postDocument: build.mutation({
      async queryFn(
        { doc, token }:
        { doc: DocumentServiceDocType, token?: string },
        { extra },
      ) {
        const { DocumentsService } = extra as Services;
        try {
          const results = await DocumentsService.setToken(token)
            .setServerSideBaseUrl()
            .postDocuments(doc);
          return { data: results?.data };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
    }),
  }),
});

export const {
  usePostDocumentMutation,
} = documentsApi;
