export const estimateDate = (body) => {
  if (body) {
    const {
      remainingCredits, level, weeks, programCode, creditsPerCourse,
    } = body;
    return {
      inputs: [
        { name: 'remainingcredits', value: remainingCredits, type: 'Integer' },
        { name: 'level', value: level },
        { name: 'weeks', value: weeks, type: 'Integer' },
        { name: 'creditsPerCourse', value: creditsPerCourse, type: 'Integer' },
        { name: 'programId', value: programCode },
      ],
      response: 'INOUT',
    };
  }

  return null;
};
export default estimateDate;
