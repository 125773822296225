import { baseApi2 } from '@/store/queries';
import { learningEndpoints } from '@/store/domains/resources/learning';
import type { LearningAttendances, CourseActivityGroups } from '@/types/api/learning';

export const attendancesApi = baseApi2.injectEndpoints({
  endpoints: (build) => ({
    getAttendancesByMembershipId: build.query<LearningAttendances, { membershipId: string }>(
      { query: ({ membershipId }) => ({ url: learningEndpoints.getAttendancesByMembershipId(membershipId), method: 'GET' }) },
    ),
    getActivityGroupsBySectionId: build.query<CourseActivityGroups, { sectionId: string }>(
      {
        query: ({ sectionId }) => ({ url: learningEndpoints.getActivityGroupsBySectionId(sectionId), method: 'GET' }),
        transformResponse: (response: CourseActivityGroups) => {
          const { activitygroups, allowedAbsences } = response;
          return { activitygroups, allowedAbsences };
        },
      },
    ),
    getActivityGroupsBySectionIds: build.query<CourseActivityGroups[], { sourceIds: string[] }>({
      async queryFn(
        { sourceIds },
        api,
        extraOptions,
        baseQuery,
      ) {
        const activityDataPromises = sourceIds.map(async (sourceId: string) => {
          const response = await baseQuery({ url: learningEndpoints.getActivityGroupsBySectionId(sourceId), method: 'GET' });
          return response.data;
        });

        const activityData = await Promise.all(activityDataPromises);

        return { data: activityData };
      },
    }),
    getAttendancesByMembershipIds: build.query<LearningAttendances[], { membershipIds: string[] }>({
      async queryFn(
        { membershipIds },
        api,
        extraOptions,
        baseQuery,
      ) {
        const attendanceDataPromises = membershipIds.map(async (membershipId: string) => {
          const response = await baseQuery({ url: learningEndpoints.getAttendancesByMembershipId(membershipId), method: 'GET' });
          return response.data;
        });

        const attendanceData = await Promise.all(attendanceDataPromises);

        return { data: attendanceData };
      },
    }),
  }),
});

export const {
  useGetAttendancesByMembershipIdQuery,
  useGetActivityGroupsBySectionIdQuery,
  useGetAttendancesByMembershipIdsQuery,
  useGetActivityGroupsBySectionIdsQuery,
} = attendancesApi;
