import { keyframes } from '@mui/styled-engine';

export const bellSwayAnimation = keyframes`
  from, to { transform: rotate(0deg); }
  7.5% { transform: rotate(25deg); }
  30% { transform: rotate(-25deg); }
  45% { transform: rotate(15deg); }
  58% { transform: rotate(-10deg); }
  70% { transform: rotate(5deg); }
  87.5% { transform: rotate(-2deg); }
`;

export const bellClapperSwayAnimation = keyframes`
  15% { transform: translateX(-0.8rem) rotate(7deg); }
  32.5% { transform: translateX(0.3rem) rotate(-7deg); }
  50%, 80% { transform: translateX(-0.5rem) rotate(7deg); }
  65% { transform: translateX(0.1rem) rotate(-7deg); }
  90% { transform: translateX(0rem) rotate(-7deg); }
`;
