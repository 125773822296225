import { baseApi, isQueryFnErrorResponse } from '@/store/queries';
import type { Services } from '@/services';
import type { Person,
  Name,
  AvatarImage,
  Information,
  Military } from '@/types/api/persons';

export const personsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    // build.query<ResultType, QueryArg>
    // QueryArg - The type of the input that will be passed as the only parameter
    //            to the query property of the endpoint,
    //            or the first parameter of a queryFn property if used instead.
    getPersonById: build.query<Person, unknown>({
      async queryFn(
        { personId, token }: { personId: string, token?:string },
        { extra },
      ) {
        const { PersonsService } = extra as Services;
        try {
          const results = await PersonsService
            .setToken(token)
            .setServerSideBaseUrl()
            .getPersonByPersonId(personId);
          return { data: results.data };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
    }),
    getOfficialName: build.query<Name, unknown>({
      queryFn: async (
        { personId, token }: { personId: string, token?:string },
        { extra },
      ) => {
        const { PersonsService } = extra as Services;
        try {
          const results = await PersonsService
            .setToken(token)
            .setServerSideBaseUrl()
            .getNamesByPersonId(personId);
          const officialName = results.data?.find((name: { type: string }) => name?.type?.toLowerCase() === 'official');
          return { data: officialName };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
      // transformResponse: (response: Names) =>
      // response.find((name) => name?.type?.toLowerCase() === 'official'),
    }),
    getAvatar: build.query<AvatarImage, unknown>({
      async queryFn(
        { personId, token }: { personId: string, token?:string },
        { extra },
      ) {
        const { PersonsService } = extra as Services;
        try {
          const results = await PersonsService
            .setToken(token)
            .setServerSideBaseUrl()
            .getAvatarByPersonId(personId);
          return { data: results.data || {} };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
    }),
    getDemographicsByPersonId: build.query<Information, unknown>({
      async queryFn(
        { personId, token }: { personId: string, token?:string },
        { extra },
      ) {
        const { PersonsService } = extra as Services;
        try {
          const results = await PersonsService
            .setToken(token)
            .setServerSideBaseUrl()
            .getDemographicsByPersonId(personId);
          return { data: results.data };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
    }),
    // TODO: define response interface
    getPersons: build.query({
      async queryFn(
        { personId, token }: { personId: string, token?:string },
        { extra },
      ) {
        const { PersonsService } = extra as Services;
        try {
          const results = await Promise.all([
            PersonsService
              .setToken(token)
              .setServerSideBaseUrl()
              .getUserAttributesByPersonId(personId),
            PersonsService
              .setToken(token)
              .setServerSideBaseUrl()
              .getPersonByPersonId(personId),
          ]);
          const [userAttributeResponse, personResponse] = results;
          const userDefined = userAttributeResponse?.data?.attributes?.['User Defined'] || [];
          const personEmail = personResponse?.data?.externalSystemIds?.UNIVERSITY_EMAIL?.[0] || '';
          const salesForceId = personResponse?.data?.externalSystemIds?.SALESFORCE_ID?.[0];
          return { data: { userDefined, personEmail, salesForceId } };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
    }),
    getMilitaryStatus: build.query<Military, unknown>({
      async queryFn(
        { personId, token }: { personId: string, token?:string },
        { extra },
      ) {
        const { PersonsService } = extra as Services;
        try {
          const results = await PersonsService
            .setToken(token)
            .setServerSideBaseUrl()
            .getMilitaryStatus(personId);
          return { data: results.data };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
    }),
  }),
});

export const {
  useGetPersonsQuery,
  useGetPersonByIdQuery,
  useGetOfficialNameQuery,
  useGetAvatarQuery,
  useGetDemographicsByPersonIdQuery,
  useGetMilitaryStatusQuery,
} = personsApi;
