import { DateTime } from 'luxon';
import Config from '@/helpers/config';
import { getCurrentDate, toDateTime } from '@/myphoenix/utils/date-time-functions';
import { AchievementTypes } from '@/helpers/enums/notifications';
import { Notification0 } from '@/types/api/communication';
import { hasQueryParam } from '@/helpers/window-functions';
import { MOCK_SCENARIO_KEY } from '@/helpers/session-storage/constants';
import { mockTemplateIds } from '@/mocks/types/mockNotification';

const LOGON_EVENT = 'LOGONEVENT';
const HOME_ALERTS_CONTEXT_PATH = '/home/alerts';
const { contentPipelineTemplateIds } = Config;
const notificationTemplateIds = hasQueryParam(MOCK_SCENARIO_KEY)
  ? mockTemplateIds
  : contentPipelineTemplateIds;

const filterNotLogonEvent = (entry: Notification0) => entry?.notificationSubType !== LOGON_EVENT;
const filterLogonEvent = (entry: Notification0) => entry?.notificationSubType === LOGON_EVENT;
const filterContextHasHomeAlerts = (entry: Notification0) => entry?.contexts
  ?.some((context) => context.endsWith(HOME_ALERTS_CONTEXT_PATH));
const getAchievementType = (entry: Notification0): AchievementTypes => {
  const { deansList, presidentsList, skill } = notificationTemplateIds;
  switch (entry.templateId) {
    case deansList:
      return AchievementTypes.DeansList;
    case presidentsList:
      return AchievementTypes.PresidentsList;
    case skill:
      return AchievementTypes.Skill;
    default:
      return null;
  }
};

export const filteredLogonEvents = (data: Notification0[] = []) => data
  ?.filter((entry) => filterLogonEvent(entry) && filterContextHasHomeAlerts(entry));

export const filteredNonLogonEvents = (data: Notification0[] = []) => data
  ?.filter((entry) => filterNotLogonEvent(entry) && filterContextHasHomeAlerts(entry));

export const filteredUnreadNonLogonEvents = (data: Notification0[] = []) => data
  ?.filter((entry) => filterNotLogonEvent(entry)
  && filterContextHasHomeAlerts(entry) && !entry.read);

export const getAchievements = (data: Notification0[] = []) => data
  ?.filter((entry) => filterLogonEvent(entry)
  && filterContextHasHomeAlerts(entry)
  && Object.values(notificationTemplateIds).indexOf(entry?.templateId) > -1);

export const getLatestAchievement = (
  data: Notification0[],
  onlyNonDismissed: boolean = true,
  tieBreakerType: AchievementTypes = AchievementTypes.PresidentsList,
  ignoreSkills: boolean = false,
) => {
  const achievements = getAchievements(data)?.reduce((list, achievement) => {
    const achievementData = {
      ...achievement,
      ...achievement?.states,
      startDate: toDateTime(achievement?.states?.startDate),
      expiryDate: toDateTime(achievement?.states?.expiryDate),
      type: getAchievementType(achievement),
    };

    if (achievementData.type
      && (!onlyNonDismissed || achievementData?.status?.action !== 'COMPLETED')
      && (!ignoreSkills || achievementData.type !== AchievementTypes.Skill)
    ) {
      return [...list, achievementData];
    }
    return list;
  }, []);

  // There are no achievements
  if (!achievements?.length) return null;

  // Skill achievements take precedence
  const now = getCurrentDate();
  const skillAchievements = achievements.filter(
    (achievement) => {
      if (achievement?.type !== AchievementTypes.Skill) return false;
      const { startDate } = achievement;
      const startDiff = now.diff(startDate, 'days');
      return (startDiff?.days || 0) < 90;
    },
  );
  if (skillAchievements?.length) {
    skillAchievements.sort((a, b) => b.startDate.diff(a.startDate));
    return skillAchievements[0];
  }

  // Non-skill achievements
  const nonSkillAchievements = achievements.filter(
    (achievement) => (achievement?.type !== AchievementTypes.Skill),
  );
  if (nonSkillAchievements?.length) {
    // Sort with tie breaker type
    nonSkillAchievements.sort((a, b) => {
      const expiryDiff = b.expiryDate.diff(a.expiryDate, 'minutes');
      if (expiryDiff?.minutes === 0) {
        return a.type === tieBreakerType ? -1 : 1;
      }
      return expiryDiff;
    });
    return nonSkillAchievements[0];
  }

  return null;
};

type AwardPeriod = {
  year: string;
  startMonth: string;
  endMonth: string;
};

export const getAwardPeriod = (expiryDate: DateTime): AwardPeriod => {
  const end = expiryDate.toUTC().set({ day: 1 }).minus({ months: 6 });
  const start = end.toUTC().minus({ months: 5 });
  return {
    year: start.toFormat('yyyy'),
    startMonth: start.toFormat('MMMM'),
    endMonth: end.toFormat('MMMM'),
  };
};
