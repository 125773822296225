import configs from '@/helpers/config';
import { navigate } from '@/helpers/window-functions';

const { blackboard } = configs;

const getBBRootUrl = () => blackboard.baseUrl;

const getBBClassUrl = (sectionId: string) => (sectionId
  ? `${blackboard.classroomBaseUrl}&courseId=${sectionId}` : getBBRootUrl());

const openBlackboard = (sectionId: string) => {
  navigate(sectionId ? getBBClassUrl(sectionId) : getBBRootUrl());
};

export {
  getBBRootUrl,
  getBBClassUrl,
  openBlackboard,
};
