import { baseApi, isQueryFnErrorResponse } from '@/store/queries';
import type { Services } from '@/services';

export type AcademicUrls = {
  name: string;
  url: string;
};
export type AcademicCatalogs = {
  type: string;
  Urls: AcademicUrls[];
};
export type AcademicLinkData = {
  irn: number;
  programType: string;
  programVersion: string;
  Catalogs: AcademicCatalogs[];
};

export const academicApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAcademicCatalogList: build.query({
      async queryFn(
        { personId, token }:
        { personId: string, token?:string },
        { extra },
      ) {
        const { StudentService } = extra as Services;
        try {
          const results = await StudentService.setToken(token)
            .setServerSideBaseUrl()
            .getAcademicCatalogList(personId);
          return { data: results?.data };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
    }),
  }),
});

export const {
  useGetAcademicCatalogListQuery,
} = academicApi;
