const microservicesBase = process?.env?.NEXT_PUBLIC_MICROSERVICES_BASE || '';

export const personEndpoints = {
  getPersonByPersonId: (
    personId: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/persons/v1/person/${personId}`,
  getNamesByPersonId: (
    personId: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/persons/v1/person/${personId}/names`,
  getAvatarByPersonId: (
    personId: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/persons/v1/person/${personId}/avatar/image`,
  getPersonByProfileId: (
    profileId: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/persons/v1/persons?externalSystem=PROFILE_ID&externalSystemId=${profileId}`,
  getDemographicsByPersonId: (
    personId: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/persons/v1/person/${personId}/information`,
  getEventsByPersonId: (
    personId: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/persons/v1/person/${personId}/events-count?context=message,logon`,
  getUserAttributesByPersonId: (
    personId: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/persons/v1/person/attributes/${personId}`,
  getMilitaryStatus: (
    personId: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/persons/v1/person/${personId}/military`,
};

export default personEndpoints;
