const microservicesBase = process?.env?.NEXT_PUBLIC_MICROSERVICES_BASE || '';

export const programsEndpoints = {
  getTemplateByCodeAndVersion: (
    // NOTE: This endpoint is supposed to receive a code, not an id
    code: string,
    version: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/programs/v3/templates?$filter=programId eq '${code}' and version eq '${version}'`,
  getProgramInfoByProgramOfferingId: (
    programOfferingId: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/programs/v3/programEligibility/${programOfferingId}`,
};

export default programsEndpoints;
