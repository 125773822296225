import Script from 'next/script';

export function GoogleTagManagerScript() {
  return process.env.NEXT_PUBLIC_GTM_ID && (
    <>
      <Script
        id="gtm-init"
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,l){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});
        })(window,'dataLayer');`,
        }}
      />
      <Script id="gtm-tag" strategy="afterInteractive" src={`https://www.googletagmanager.com/gtm.js?id=${process.env.NEXT_PUBLIC_GTM_ID}`} />
    </>
  );
}

export default GoogleTagManagerScript;
