import { baseApi2 } from '@/store/queries';
import { contactsEndpoints } from '@/store/domains/resources/contacts';
import type { EmailModel, PhoneModel, MSUnivContactsWrapper } from '@/types/api/contacts';

export const contactsApi = baseApi2.injectEndpoints({
  endpoints: (build) => ({
    getEmailsByPersonId: build.query<EmailModel[], { personId: string }>(
      { query: ({ personId }) => ({ url: contactsEndpoints.getEmailsByPersonId(personId), method: 'GET' }) },
    ),
    getEmailsByPersonIds: build.query<EmailModel[], {
      personIds: string[],
    }>({
      async queryFn(
        { personIds },
        api,
        extraOptions,
        baseQuery,
      ) {
        if (!personIds || personIds.length < 1) {
          return { data: [] };
        }

        let error;
        const emailsByPersonIdPromises: Promise<EmailModel>[] = personIds.map(
          async (personId: string) => {
            const response = await baseQuery({ url: contactsEndpoints.getEmailsByPersonId(personId), method: 'GET' });
            if (response.error) {
              error = response.error;
            }
            return response.data;
          },
        );

        const emailsByPersonIdData = await Promise.all(emailsByPersonIdPromises);

        if (error) {
          return { error };
        }

        return { data: emailsByPersonIdData.flatMap((data) => data) };
      },
    }),
    getPhonesByPersonId: build.query<PhoneModel[], { personId: string }>(
      { query: ({ personId }) => ({ url: contactsEndpoints.getPhonesByPersonId(personId), method: 'GET' }) },
    ),
    getSupportContactsByPersonIdAndProgramCode:
      build.query<MSUnivContactsWrapper, { personId: string, programCode: string }>(
        { query: ({ personId, programCode }) => ({ url: contactsEndpoints.getSupportContactsByPersonIdAndProgramCode(personId, programCode), method: 'GET' }) },
      ),
  }),
});

export const {
  useGetEmailsByPersonIdQuery,
  useGetEmailsByPersonIdsQuery,
  useGetPhonesByPersonIdQuery,
  useGetSupportContactsByPersonIdAndProgramCodeQuery,
} = contactsApi;
