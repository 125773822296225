import React from 'react';
import { Link, LinkProps } from '@mui/material';
import getLinkProperties from '@/helpers/get-link-properties';
import { LinkTarget } from '@/types/linkTarget';

interface StandardLinkProps {
  href?: string,
  children?: React.ReactNode,
  targetOverride?: LinkTarget,
}

function StandardLink({
  href,
  targetOverride = null,
  children,
  ...rest
}: StandardLinkProps & LinkProps) {
  const { target, url } = getLinkProperties(href, targetOverride);

  return (
    <Link
      href={url}
      target={target}
      {...rest}
    >
      {children}
    </Link>
  );
}

export default StandardLink;
