import { styled } from '@mui/material/styles';
import Icon from '@/components/atoms/icon';
import StandardLink from '@/components/atoms/standard-link';

export const LinkWrapper = styled('div')(({ theme }) => ({
  boxSizing: 'inherit',
  color: theme.palette.almostBlack.main,
  marginBottom: theme.spacing(2),
  cursor: 'pointer',
  '& :hover': {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    '& svg': {
      fill: theme.palette.primary.main,
    },
  },
}));

export const StyledLink = styled(StandardLink)(({ theme }) => ({
  display: 'flex',
  fontSize: theme.spacing(1.7),
  fontWeight: 500,
  margin: 0,
  textDecoration: 'none',
  color: theme.palette.almostBlack.main,
}));

export const StyledIcon = styled(Icon)(({ theme }) => ({
  position: 'relative',
  verticalAlign: 'middle',
  marginRight: theme.spacing(1.5),
}));
