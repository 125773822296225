const constants = [
  { VoucherStatus: 'Accepted',
    messageToDisplayInUI: 'Your voucher has been accepted. No action is needed if no changes occur to the course details, or the start & end dates listed on your voucher.' },
  { VoucherStatus: 'In Review',
    messageToDisplayInUI: 'Your voucher is being reviewed. Voucher status updates when the review is completed. Please allow 1 – 2 business days after submission.' },
  { VoucherStatus: 'Duplicate',
    messageToDisplayInUI: 'This voucher has already been submitted. No action is needed.' },
  { VoucherStatus: 'Invalid',
    VoucherStatusSRMMessage: 'Course ID on voucher does not match schedule',
    messageToDisplayInUI: 'The course ID on the voucher doesn’t match your schedule. Please reach out to your university contact for help.' },
  { VoucherStatus: 'Invalid',
    VoucherStatusSRMMessage: 'Course start date on voucher does not match schedule',
    messageToDisplayInUI: "The course start date on voucher doesn't match your schedule. Please reach out to your university contact for help." },
  { VoucherStatus: 'Invalid',
    VoucherStatusSRMMessage: 'Course end date on voucher does not match schedule',
    messageToDisplayInUI: 'The course end date on voucher doesn’t match your schedule. Please reach out to your university contact for help.' },
  { VoucherStatus: 'Invalid',
    VoucherStatusSRMMessage: 'Voucher cannot be for $0',
    messageToDisplayInUI: 'Your voucher cannot be for $0. Please reach out to your university contact for help.' },
  { VoucherStatus: 'Invalid',
    VoucherStatusSRMMessage: 'Invalid document',
    messageToDisplayInUI: 'The document uploaded is not a valid voucher. Please upload a valid voucher.' },
  { VoucherStatus: 'Invalid',
    VoucherStatusSRMMessage: 'Cannot open attachment',
    messageToDisplayInUI: "We can't open your uploaded file. Please check the file format and size, then re-upload." },
  { VoucherStatus: 'Invalid',
    VoucherStatusSRMMessage: 'LOC/Voucher missing signature',
    messageToDisplayInUI: 'Your document is missing a signature. Sign and reupload your voucher.' },
  { VoucherStatus: 'Invalid',
    VoucherStatusSRMMessage: 'Course dropped',
    messageToDisplayInUI: 'Please reach out to your university contact for help.' },
  { VoucherStatus: 'Invalid',
    VoucherStatusSRMMessage: 'Employer is not setup for DB. Please submit a New Employer Setup request.',
    messageToDisplayInUI: 'Please reach out to your university contact for help.' },
];
export default constants;
