import configs from '@/helpers/config';
import { navigate } from '@/helpers/window-functions';

const { brightspace } = configs;
const getBrightspaceBaseUrl = () => brightspace.baseUrl;
// todo add deep link if possible
const getBrightspaceClassUrl = () => brightspace.baseUrl;

const openBrightspace = () => {
  navigate(getBrightspaceBaseUrl());
};

export {
  getBrightspaceBaseUrl,
  getBrightspaceClassUrl,
  openBrightspace,
};
