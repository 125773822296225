import { getTokenFromSessionStorage, getCustomTokenFromSessionStorage } from '@/helpers/auth';
import builder from '@builder.io/react';

const authorizationInterceptor = (requestConfig) => {
  // The token should be set from server side calls if not its assumed client
  const getCustomToken = requestConfig.customAuth;
  const token = (getCustomToken || builder.editingMode)
    ? getCustomTokenFromSessionStorage() : getTokenFromSessionStorage();

  if ((!requestConfig.headers.Authorization && !requestConfig.noAuth) || getCustomToken) {
    const configUpdate = requestConfig;
    if (!token) {
      throw new Error('Failed to retrieve token.');
    }
    configUpdate.headers.Authorization = `Bearer ${token}`;
    return configUpdate;
  }
  return requestConfig;
};

export default authorizationInterceptor;
