const jsonHandlerInterceptor = (requestConfig) => {
  const stringBody = requestConfig?.body ? JSON.stringify(requestConfig.body) : '';
  return ({
    ...requestConfig,
    ...(((requestConfig.headers || '') === '')
    && {
      headers: {
        ...requestConfig?.headers,
        'Content-Type': 'application/json',
      },
    }),
    ...(((stringBody !== '{}') && (requestConfig.body || '') !== '' && typeof requestConfig.body !== 'string')
    && { body: stringBody }),
  });
};

export default jsonHandlerInterceptor;
