import constants from '@/constants/invalid-voucher-status';

// get voucher notes/comments/substatus based on ACCEPTED/IN REVIEW/DUPLICATE/Invalid status
// assign it to invalidReason(overload it)
const getVoucherStatusInvalidReason = (status: string, invalidReason: string) => {
  let comments = [];
  const inValidStaticComment = 'Please reach out to your university contact for help.';
  comments = constants
    .filter((m) => (m.VoucherStatus === status
                            && ((m.VoucherStatusSRMMessage
                                 && m.VoucherStatusSRMMessage === invalidReason)
                            || (!m.VoucherStatusSRMMessage))
    ));
  const invalid = status === 'Invalid' ? inValidStaticComment : invalidReason;
  const message = comments && comments.length > 0 ? comments[0].messageToDisplayInUI
    : invalid;
  return message;
};

export const getTransformedPersonVouchersData = (vouchersList:any[]) => {
  vouchersList.map((v) => {
    const voucher = v;
    if (voucher.status === 'Submitted'
           || voucher.status === 'Pending Review'
           || voucher.status === 'Course/Schedule Update'
           || voucher.status === 'Pending Schedule') {
      voucher.status = 'In Review';
    }
    if (voucher.status === 'Valid') {
      voucher.status = 'Accepted';
    }
    if (voucher.status === 'Abandoned') {
      voucher.status = 'Duplicate';
    }
    voucher.name = `Voucher-${v.id.substring(v.id.length - 4)}`;
    voucher.invalidReason = getVoucherStatusInvalidReason(v.status, v.invalidReason) ?? null;
    return voucher;
  });

  return vouchersList;
};

export default getTransformedPersonVouchersData;
