import BaseService from '@/services/wrappers/service';

export default class CoursesService extends BaseService {
  constructor(client) {
    super(client, 'courses');
    this.getCurriculum = this.getCurriculum.bind(this);
  }

  async getCurriculum(courseCode) {
    return this.client.get(`${this.baseEndpointUrl}/v1/templates/curriculum?courseCode=${courseCode}`);
  }
}
