import { useTracking } from 'react-tracking';
import { publishMyPhoenixComponentEvent } from '@/myphoenix/utils/event-functions';

const trackHandler = (trackingData) => {
  publishMyPhoenixComponentEvent(trackingData);
};

function ReactTracker({ children }) {
  const { Track } = useTracking(
    {},
    {
      dispatch: (data) => {
        trackHandler(data);
      },
    },
  );
  return (
    <Track>
      {children}
    </Track>
  );
}

export default ReactTracker;
