import { useMemo } from 'react';
import { useGetTemplatesByCodesAndVersionsQuery } from '@/store/queries/v2/programs';
import { useGetAllProgramsByPersonIdQuery } from '@/store/queries/v2/student';
import {
  TransformedProgram,
  getTransformedProgramsData,
  getFormattedProgramData,
} from '@/store/helpers/programs';
import { ByIdObject, convertObjectArrayToByIdObject } from '@/helpers/object';
import { useSelector } from 'react-redux';

const useGetProgramsByIdHook = (personId: string, skip: boolean = false) => {
  const {
    data: allPrograms = [],
    isFetching: isGetProgramsByIdFetching,
    isError: isGetProgramsByIdError,
  } = useGetAllProgramsByPersonIdQuery({ personId }, { skip: skip || !personId });

  const transformedAllProgramsData: TransformedProgram[] = useMemo(() => {
    if (!isGetProgramsByIdFetching && !isGetProgramsByIdError && allPrograms.length) {
      return getTransformedProgramsData(allPrograms);
    }
    return [];
  }, [allPrograms, isGetProgramsByIdFetching, isGetProgramsByIdError]);

  const codesAndVersions = useMemo(() => transformedAllProgramsData?.map((
    program: { code: string, version: string },
  ) => ({ code: program.code, version: program.version })) || [], [transformedAllProgramsData]);

  const {
    data: programTemplates,
    isFetching: isGetTemplatesFetching,
    isError: isGetTemplatesError,
  } = useGetTemplatesByCodesAndVersionsQuery(
    { codesAndVersions },
    { skip: skip
      || isGetProgramsByIdFetching
      || isGetProgramsByIdError
      || !codesAndVersions },
  );

  const selectedProgramId = useSelector((state: {
    session: {
      selectedProgramId: string,
    }
  }) => state.session.selectedProgramId);

  const data = useMemo(() => {
    if (
      isGetProgramsByIdError
      || isGetTemplatesError
      || isGetProgramsByIdFetching
      || isGetTemplatesFetching
    ) {
      return {};
    }

    const {
      primaryProgramId,
      programsListArray,
    } = getFormattedProgramData(transformedAllProgramsData, programTemplates);

    // TODO: we lose type safety here
    const programsList: ByIdObject = convertObjectArrayToByIdObject(programsListArray);
    // TODO:  casting to FormattedProgramData unravels a lot of existing unsafe-ish types downstream
    // const primaryProgram: FormattedProgramData = programsList?.byId[primaryProgramId];
    const primaryProgram = programsList?.byId[primaryProgramId];

    return {
      primaryProgramId,
      primaryProgram,
      selectedProgramId,
      programsList,
    };
  }, [
    isGetProgramsByIdError,
    isGetTemplatesError,
    isGetProgramsByIdFetching,
    isGetTemplatesFetching, transformedAllProgramsData,
    programTemplates,
    selectedProgramId,
  ]);

  return {
    data,
    isFetching: isGetProgramsByIdFetching || isGetTemplatesFetching,
    isError: isGetProgramsByIdError || isGetTemplatesError,
  };
};

export default useGetProgramsByIdHook;
