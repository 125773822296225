import prefetchInterceptor from '@/services/interceptors/fetch/prefetch-interceptor';
import authorizationInterceptor from '../interceptors/fetch/authorization-interceptor';
import jsonHandlerInterceptor from '../interceptors/fetch/json-handler-interceptor';
import resourceConflictInterceptor from '../interceptors/fetch/resource-conflict-interceptor';
import { trackingIdInterceptor } from '../interceptors/fetch/tracking-id-interceptor';
import retryInterceptor from '../interceptors/fetch/retry-interceptor';
import errorTrackingInterceptor from '../interceptors/fetch/error-tracking-interceptor';
import FetchClient from './fetch-client';

const proxyClient = () => {
  const fetchClient = new FetchClient();
  fetchClient.applyRequestInterceptor(authorizationInterceptor);
  fetchClient.applyRequestInterceptor(jsonHandlerInterceptor);
  fetchClient.applyRequestInterceptor(trackingIdInterceptor);
  fetchClient.applyRequestInterceptor(prefetchInterceptor);
  fetchClient.applyResponseInterceptor(resourceConflictInterceptor);
  fetchClient.applyResponseInterceptor(retryInterceptor);
  fetchClient.applyResponseInterceptor(errorTrackingInterceptor);
  return fetchClient.client();
};

export const proxyClientInstance = proxyClient();

export default proxyClient;
