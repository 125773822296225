import { StudentCourseMembership } from '@/types/api/student/studentCourseMembership';
import { useGetMembershipsByPersonIdAndDateRangeQuery } from '@/store/queries/v2/student';
import { Course } from '@/types/courseInfoData';
import { useGetCoursesBySourceIdsQuery } from '@/store/queries/v2/courses';
import { augmentSectionsFromMemberships } from '@/myphoenix/utils/course-functions';

function useGetMembershipsAndCourses(personId: string, skip: boolean = false) {
  const {
    data: memberships = [],
    isFetching: isGetMembershipsFetching,
    isError: isGetMembershipsError,
  } = useGetMembershipsByPersonIdAndDateRangeQuery(
    { personId },
    { skip: skip || !personId },
  ) as {
    data: StudentCourseMembership[],
    isFetching: boolean,
    isError: boolean,
  };
  const {
    data: sections = [],
    isFetching: isGetCoursesFetching,
    isError: isGetCoursesError,
  } = useGetCoursesBySourceIdsQuery(
    { sourceIds: memberships?.map(
      (membership: { sourceId?: string }) => membership.sourceId,
    ) },
    { skip: !memberships.length },
  ) as {
    data: Course[],
    isFetching: boolean,
    isError: boolean,
  };
  const courses: Course[] = sections.length
    ? augmentSectionsFromMemberships(memberships, sections)
    : sections;

  return {
    memberships,
    courses,
    isFetching: isGetMembershipsFetching || isGetCoursesFetching,
    isError: isGetMembershipsError || isGetCoursesError,
  };
}

export default useGetMembershipsAndCourses;
