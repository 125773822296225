import headerLinks from './headerLinks';

const { headerLinkList } = headerLinks;

// User defined attributes may come in a piped list ( ex : |ChatBot|3CASkills)
// So they need to be split into an array of individual attributes
export function formatUserDefinedAttributes(userDefinedAttributes) {
  const pipedUserDefinedAttributes = userDefinedAttributes?.filter((attribute) => attribute?.includes('|'))?.[0];
  const otherUserDefinedAttributes = userDefinedAttributes?.filter((attribute) => !attribute?.includes('|'));
  const listOfUserDefinedAttributes = pipedUserDefinedAttributes?.split('|')?.filter((word) => !!word?.length) || [];
  return [...listOfUserDefinedAttributes, ...otherUserDefinedAttributes];
}

export function linkDetokenizer(userDefinedAttributes, emailAddress, programId) {
  const detokenizerList = [
    { token: 'USERS_UNIVERSITY_EMAIL', value: emailAddress },
    { token: 'DYNAMIC_PROGRAM_ID', value: programId },
  ];
  // All is default. However, this list should also contain things
  // like 3CASkills or a program (note case sensitivity here due to using a map for performance)
  const userGroups = [...(formatUserDefinedAttributes(userDefinedAttributes)), programId];

  const remappedLinks = headerLinkList.map((list) => {
    const parsedLinks = list.links.flatMap((link) => {
      const linkInGroup = userGroups?.some((key) => link.group[key]);
      let replacedUrl = link.url;
      const { group, ...rest } = link;

      if (linkInGroup) {
        detokenizerList.forEach((detokenizer) => {
          if (link.url.includes(detokenizer.token)) {
            replacedUrl = replacedUrl.replace(
              detokenizer.token,
              detokenizer.value,
            );
          }
        });
        return { ...rest, url: replacedUrl };
      }

      return [];
    });
    return { ...list, links: parsedLinks };
  });
  return remappedLinks;
}

export default linkDetokenizer;
