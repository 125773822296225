import { baseApi } from '@/store/queries';

export const alertsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAlerts: build.query({
      async queryFn(
        { personId, token, baseUrl },
        { extra: { CommunicationService } },
      ) {
        try {
          const results = await CommunicationService.setToken(token)
            .setServerSideBaseUrl(baseUrl).getAlertsByPersonId(personId);
          return { data: results.data };
        } catch (error) {
          return {
            error: {
              message: error?.response?.statusText,
              statusCode: error?.response?.status,
            },
          };
        }
      },
    }),
  }),
});

export const { useGetAlertsQuery } = alertsApi;
export default alertsApi.endpoints;
