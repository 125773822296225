import { errorToCode, trackTimeoutError } from '../../../myphoenix/utils/error-functions';
import { getRequestId, getCorrelationId } from './tracking-id-interceptor';

const statusCodes = [408, 504];

const errorTrackingInterceptor = (response, requestConfig) => {
  if (response && statusCodes.includes(response.status)) {
    trackTimeoutError(
      errorToCode(response, requestConfig),
      getRequestId(requestConfig),
      getCorrelationId(requestConfig),
    );
  }
  return null;
};

export default errorTrackingInterceptor;
