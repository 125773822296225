import { StudentCourseMembership } from '@/types/api/student/studentCourseMembership';
import { getCurrentDate, toDateTime } from '@/myphoenix/utils/date-time-functions';

const sortBy = (key: string) => (a: StudentCourseMembership, b: StudentCourseMembership) => {
  if ((a as any)[key] > (b as any)[key]) return 1;
  if ((b as any)[key] > (a as any)[key]) return -1;
  return 0;
};

export function sortMemberships(memberships: StudentCourseMembership[], key: string = 'sourceId') {
  const allMembershipIds: string[] = [];
  const currentMembershipIds: string[] = [];
  const futureMembershipIds: string[] = [];
  const pastMembershipIds: string[] = [];
  const now = getCurrentDate();

  memberships?.concat().sort(sortBy('startDate')).forEach((membership) => {
    allMembershipIds.push((membership as any)[key]);
    if (toDateTime(membership?.endDate) < now) {
      pastMembershipIds.push((membership as any)[key]);
    } else if (toDateTime(membership?.startDate) > now) {
      futureMembershipIds.push((membership as any)[key]);
    } else {
      currentMembershipIds.push((membership as any)[key]);
    }
  });

  return {
    allMembershipIds,
    currentMembershipIds,
    futureMembershipIds,
    pastMembershipIds: pastMembershipIds.reverse(),
  };
}

const getCurrentAndFutureCourses = (courses: StudentCourseMembership[]) => {
  function getDaysBeforeStart(course: StudentCourseMembership) {
    const currentDate = getCurrentDate();
    const nextCourseStartDate = toDateTime(course?.startDate);
    const daysBeforeStartDate = (nextCourseStartDate.diff(currentDate, 'days')).toObject();
    return daysBeforeStartDate.days;
  }

  function getDaysBeforeEnd(course: StudentCourseMembership) {
    const currentDate = getCurrentDate();
    const nextCourseEndDate = toDateTime(course?.endDate);
    const daysBeforeEndDate = (nextCourseEndDate.diff(currentDate, 'days')).toObject();
    return daysBeforeEndDate.days;
  }

  const getCurrentCourses = courses.filter((course) => {
    const startDays = getDaysBeforeStart(course);
    const endDays = getDaysBeforeEnd(course);
    return (startDays < 0 && endDays > 0) ? course : false;
  });

  const getFutureCourses = courses.filter((course) => {
    const startDays = getDaysBeforeStart(course);
    return (startDays <= 7 && startDays > 0) ? course : false;
  });

  let heading = 'Current Classes';
  let filteredCourses = getCurrentCourses;
  if (getFutureCourses.length && !getCurrentCourses.length) {
    heading = 'Upcoming Classes';
    filteredCourses = getFutureCourses;
  }
  return { heading, filteredCourses };
};

export default getCurrentAndFutureCourses;
