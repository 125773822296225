import { TrackingHook, useTracking } from 'react-tracking';
import { createEventObject } from '@/myphoenix/utils/event-functions';

export const useClickEventTracking = () => {
  const { trackEvent }: TrackingHook<{ trackingMethod: string, componentName: string, properties?: any; }> = useTracking({ trackingMethod: 'trackEvent' });
  const trackClick = (componentName: string, properties?: { [key: string]: any }[]) => {
    trackEvent(createEventObject(
      componentName,
      properties,
    ));
  };
  return trackClick;
};

export default useClickEventTracking;
