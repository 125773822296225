import { baseApi, isQueryFnErrorResponse } from '@/store/queries';
import type { Services } from '@/services';
import { getRecentWorkflow, getValueFromWorkflow } from '@/store/helpers/task';
import { DateTime } from 'luxon';

export const workflowApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getLatestApplicationId: build.query({
      async queryFn(
        { personId, token }: { personId: string; token?: string },
        { extra },
      ) {
        const { SagasService } = extra as Services;
        try {
          const result = await SagasService.setToken(token).setServerSideBaseUrl()
            .getApplicationWorkflowByPersonId(personId);
          const { data: applicationWorkflows } = result;
          const applicationId = getRecentWorkflow(applicationWorkflows).applicationId || null;
          return { data: { applicationId } };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
    }),
    getLatestWorkflow: build.query({
      async queryFn(
        { personId, token }: { personId: string; token?: string },
        { extra },
      ) {
        const { SagasService } = extra as Services;
        try {
          const result = await SagasService.setToken(token).setServerSideBaseUrl()
            .getApplicationWorkflowByPersonId(personId);
          const { data: applicationWorkflows } = result;
          const recentWorkflow = getRecentWorkflow(applicationWorkflows);
          const latestWorkflow = recentWorkflow?.workflow || null;
          const applicationStartDate = (Number.isInteger(latestWorkflow?.startTime)) ? DateTime?.fromMillis(latestWorkflow?.startTime).setZone('America/Phoenix').toISO() : null;
          const applicationType = latestWorkflow?.input?.applicationType;
          const applicationId = recentWorkflow?.applicationId || null;
          return {
            data: {
              applicationStartDate,
              applicationType,
              application: {
                endTime: latestWorkflow?.endTime,
                status: latestWorkflow?.status,
                version: latestWorkflow?.version,
                submissionStatus: getValueFromWorkflow(latestWorkflow, 'reviewSignSubmit', 'status'),
                submittedTime: getValueFromWorkflow(latestWorkflow, 'reviewSignSubmit', 'scheduledTime'),
                reviewStatus: getValueFromWorkflow(latestWorkflow, 'advisorReview', 'status'),
              },
              applicationId,
            },
          };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
    }),
  }),
});

export const {
  useGetLatestApplicationIdQuery,
  useGetLatestWorkflowQuery,
} = workflowApi;
