import { baseApi2 } from '@/store/queries';
import { learningEndpoints } from '@/store/domains/resources/learning';
import type { Section, CourseOutcomesType, Membership, Memberships } from '@/types/api/learning';
// eslint-disable-next-line import/no-named-as-default
import studentEndpoints from '@/store/domains/resources/student';

export const coursesApi = baseApi2.injectEndpoints({
  endpoints: (build) => ({
    getSectionBySourceId: build.query<Section, { sourceId: string }>(
      {
        query: ({ sourceId }) => ({ url: learningEndpoints.getSectionBySourceId(sourceId), method: 'GET' }),
      },
    ),
    getCoursesBySourceIds: build.query<Section[], { sourceIds: string[] }>({
      async queryFn(
        { sourceIds },
        api,
        extraOptions,
        baseQuery,
      ) {
        if (!sourceIds || sourceIds.length === 0) {
          return { data: [] };
        }
        const coursesDataPromises = sourceIds.map(async (sourceId: string) => {
          const response = await baseQuery({ url: learningEndpoints.getSectionBySourceId(sourceId), method: 'GET' });
          return response.data;
        });

        const coursesData = await Promise.all(coursesDataPromises);

        return { data: coursesData };
      },
    }),
    getCourseOutcomesByProgramId: build.query<CourseOutcomesType, { programId: string }>(
      {

        query: ({ programId }) => ({ url: studentEndpoints.getCourseOutcomes(programId), method: 'GET' }),

      },
    ),
    getMembershipsBySourceIdTypeAndRole: build.query<Memberships, {
      sourceId: string,
      sourceType: string,
      roleType: string
    }>(
      {
        query: ({
          sourceId,
          sourceType,
          roleType,
        }) => ({ url: learningEndpoints.getMembershipsBySourceIdTypeAndRole(sourceId, sourceType, roleType), method: 'GET' }),
      },
    ),
    getMembershipsBySourceIdsTypeAndRole: build.query<Membership[], {
      sourceIds: string[],
      sourceType: string,
      roleType: string }>({
      async queryFn(
        { sourceIds, sourceType, roleType },
        api,
        extraOptions,
        baseQuery,
      ) {
        if (!sourceIds || sourceIds.length < 1) {
          return { data: [] };
        }

        let error;
        const membershipPromises: Promise<Memberships>[] = sourceIds.map(async (
          sourceId: string,
        ): Promise<Memberships> => {
          const response = await baseQuery({ url: learningEndpoints.getMembershipsBySourceIdTypeAndRole(sourceId, sourceType, roleType), method: 'GET' });
          if (response.error) {
            error = response.error;
          }
          return response.data;
        });

        const membershipData = await Promise.all(membershipPromises);

        if (error) {
          return { error };
        }

        return { data: membershipData.flatMap((data) => data?.memberships) };
      },
    }),
  }),
});

export const {
  useGetSectionBySourceIdQuery,
  useGetCoursesBySourceIdsQuery,
  useGetCourseOutcomesByProgramIdQuery,
  useGetMembershipsBySourceIdTypeAndRoleQuery,
  useGetMembershipsBySourceIdsTypeAndRoleQuery,
} = coursesApi;
