import { Priority } from '@/types/api/communication';

export interface MockNotification {
  templateId?: string,
  name?: string,
  title?: string,
  description: string,
  htmlDescription?: string;
  priority?: Priority,
  contexts?: string[],
  link?: string,
  category?: string,
  notificationSubType?: string,
  startDate?: string,
  dueDate?: string,
  endDate?: string,
  statusAction?: 'ASSIGNED' | 'DISMISSED' | 'COMPLETED',
}

export const mockTemplateIds = {
  deansList: 'NOTIFICATION-DEANS-LIST',
  presidentsList: 'NOTIFICATION-PRESIDENTS-LIST',
  skill: 'NOTIFICATION-SKILL',
};
