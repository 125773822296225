import React from 'react';
import PropTypes from 'prop-types';
import {
  LinkWrapper, StyledLink, StyledIcon, StyledText,
} from '@/components/molecules/icon-link/IconLink.styles';
import { useClickEventTracking } from '@/hooks/useClickEventTracking';

/**
 * Icon Link React Component
 */
function IconLink({
  styleType = 'almost-black',
  target,
  url,
  id,
  icon,
  text,
  parentComponentName = '',
  ariaLabel,
}) {
  const trackClick = useClickEventTracking();
  const componentName = `${parentComponentName}_IconLink`;
  const header = styleType === 'header';
  const footer = styleType === 'footer';

  return (
    <LinkWrapper
      $header={header}
      $footer={footer}
    >
      <StyledLink
        $header={header}
        $footer={footer}
        href={url}
        targetOverride={target}
        onClick={() => {
          trackClick(
            componentName,
            [{ url }, { target }, { text }, { styleType }],
          );
        }}
        aria-label={ariaLabel || text}
      >
        <StyledIcon
          id={id}
          icon={icon}
          $header={header}
        />
        <StyledText
          $header={header}
        >
          {text}
        </StyledText>
      </StyledLink>
    </LinkWrapper>
  );
}

IconLink.propTypes = {
  id: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  styleType: PropTypes.string,
  target: PropTypes.string,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  parentComponentName: PropTypes.string,
  ariaLabel: PropTypes.string,
};

export default IconLink;
