import { Services } from '@/services';
import { createApi } from '@reduxjs/toolkit/query/react';

export const mockScenariosApi = createApi({
  reducerPath: 'mockScenariosApi',
  baseQuery: undefined,
  serializeQueryArgs: ({ queryArgs, endpointName }) => {
    // Destructure out params from server side to maintain cache
    const { token, baseUrl, ...cleanedArgs } = queryArgs as { token: string, baseUrl: string };
    return `${endpointName}(${JSON.stringify(cleanedArgs)})`;
  },
  endpoints: (build) => ({
    getScenarios: build.query({
      async queryFn(
        {
          mocksEnabled,
          stripeIsActive,
        },
        { extra },
      ) {
        const { MockService } = extra as Services;
        try {
          if (mocksEnabled && stripeIsActive) {
            const results = await MockService
              .getMockScenarios();
            return { data: results.data };
          }
          return { data: [] };
        } catch (error) {
          return {
            error: {
              message: error?.response?.statusText,
              statusCode: error?.response?.status,
            },
          };
        }
      },
    }),
  }),
});

export const { useGetScenariosQuery } = mockScenariosApi;
