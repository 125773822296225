import { getItem } from '@/helpers/session-storage';
import { MOCK_SCENARIO_KEY } from '@/helpers/session-storage/constants';

export const BASE_INTERNAL_SERVICES_PATH = '/api/services';
export const BASE_MOCK_INTERNAL_SERVICES_PATH = `/api/mock${BASE_INTERNAL_SERVICES_PATH}`;

export const getInternalServiceBasePath = () => {
  const mockScenarioId = getItem(MOCK_SCENARIO_KEY);
  return mockScenarioId ? BASE_MOCK_INTERNAL_SERVICES_PATH : BASE_INTERNAL_SERVICES_PATH;
};
