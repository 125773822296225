import builder from '@builder.io/react';
import { createApi } from '@reduxjs/toolkit/query/react';
import { isQueryFnErrorResponse } from '@/store/queries';
import config from '@/helpers/config';
import { ApiVersion } from '@builder.io/sdk';

const { Builder } = config;

async function getBuilderData(modelName: string) {
  // ! IMPORTANT for GDPR and CCPA compliance (Pending listener to enable) canTrack has to be false
  // ! DO NOT REMOVE THIS IS FOR INSPECTION
  // This is a public key meant to be visible to the client to pull non sensitive content
  builder.init(Builder.apiKey, false, null, null, null, Builder.apiVersion as ApiVersion);
  return builder.get(modelName).promise();
}

export const builderApi = createApi({
  reducerPath: 'builder.io',
  baseQuery: undefined,
  endpoints: (build) => ({
    getSiteStripeData: build.query({
      async queryFn() {
        try {
          const results = await getBuilderData('site-stripe');
          return { data: results?.data };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
    }),
    getCopyrightText: build.query({
      async queryFn() {
        try {
          const results = await getBuilderData('footer-copyright');
          return { data: results?.data };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
    }),
    getBuilderDataModelByName: build.query({
      async queryFn({ modelName }: { modelName: string }) {
        try {
          const results = await getBuilderData(modelName);
          return { data: results?.data };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
    }),
  }),
});

export const {
  useGetSiteStripeDataQuery,
  useGetCopyrightTextQuery,
  useGetBuilderDataModelByNameQuery,
} = builderApi;
