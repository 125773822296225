// ? Option to refactor to https://mui.com/material-ui/react-alert/ in the future
import {
  DialogContent, Slide,
} from '@mui/material';
import { forwardRef } from 'react';
import {
  Wrapper, Icon, StyledDialogContent, StyledDialog, StyledDialogTitle, StyledDialogButton,
} from './Alert.styles';

// @ts-ignore-start
// eslint-disable-next-line react/display-name
const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
// @ts-ignore-end

function NotificationAlert({
  open, title, content, onClick, buttonText,
}) {
  const handleClick = () => {
    onClick();
  };
  return (
    <StyledDialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="alert-dialog"
      // @ts-ignore
      TransitionComponent={Transition}
      maxWidth="md"
    >
      <Wrapper>
        <StyledDialogTitle id="alert-dialog-title" data-testid="alert-dialog-title" component="h1">
          <Icon icon="icon-warning-sign" />
            &nbsp;
          {title}
        </StyledDialogTitle>
        <DialogContent>
          <StyledDialogContent id="alert-dialog-description" data-testid="alert-dialog-contents">
            {content}
          </StyledDialogContent>
          <StyledDialogButton variant="contained" onClick={handleClick} data-testid="alert-dialog-button">
            {buttonText}
          </StyledDialogButton>
        </DialogContent>
      </Wrapper>
    </StyledDialog>
  );
}

export default NotificationAlert;
