import features from '@/helpers/features';
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import { publishMyPhoenixErrorEvent } from './event-functions';

export const METHOD_ERROR_CODE_MAP = new Map();
METHOD_ERROR_CODE_MAP.set('delete', '01');
METHOD_ERROR_CODE_MAP.set('get', '02');
METHOD_ERROR_CODE_MAP.set('post', '03');
METHOD_ERROR_CODE_MAP.set('put', '04');
const methodToCode = (method) => METHOD_ERROR_CODE_MAP.get(method) || '00';

const UOPX_MICROSERVICE = 'uopx.io';
const INTERNAL_MYPHX_NEXT_API = '/';

const getUOPXDomain = (url) => {
  const regexFindAllAfterLastApi = /api(?!.*api)(.*)/;
  const match = url.match(regexFindAllAfterLastApi) ?? [];
  const everythingAfterLastApiInString = match[1] ?? '';
  const urlParts = everythingAfterLastApiInString.split('/');
  return urlParts[1] || '';
};

const getThirdPartyDomain = (url) => {
  if (url?.includes('builder.io')) {
    return 'builder';
  }
  return '';
};

const getHostNameToDigits = (url) => {
  if (url.includes(UOPX_MICROSERVICE)) {
    return '10';
  }

  if (url.startsWith(INTERNAL_MYPHX_NEXT_API)) {
    return '20';
  }

  return '30';
};
const getDomain = (url) => {
  const isVendor = !url.startsWith(INTERNAL_MYPHX_NEXT_API) && !url.includes(UOPX_MICROSERVICE);
  if (isVendor) {
    return getThirdPartyDomain(url);
  }
  return getUOPXDomain(url);
};

/**
     * Convert Domain and Subdomains to Code
     * @param {*} domain
     * @param {*} url
     */
// TODO: Ensure all calls in the app are covered. (I.E. Wallets, work, etc)
const domainToDigits = (domain, url) => {
  // LOA is using 200 series and is spread across two domains, programs and student
  switch (domain) {
    case 'applications':
      if (url.indexOf('applications') >= 0) {
        return '011';
      }
      return '010';
    case 'catalogs':
      return '020';
    case 'contacts':
      if (url.indexOf('validate') >= 0) {
        if (url.indexOf('zipcode') >= 0) {
          return '032';
        }
        if (url.indexOf('address') >= 0) {
          return '033';
        }
        return '031';
      }
      if (url.indexOf('suggest') >= 0) {
        if (url.indexOf('address') >= 0) {
          return '035';
        }
        return '034';
      }
      // addresses or address
      if (url.indexOf('address') >= 0) {
        return '036';
      }
      // emails or email
      if (url.indexOf('email') >= 0) {
        return '037';
      }
      // phones or phone
      if (url.indexOf('phone') >= 0) {
        return '038';
      }
      if (url.indexOf('support') >= 0) {
        return '039';
      }
      return '030';
    case 'courses':
      if (url.indexOf('curriculum') >= 0) {
        return '041';
      }
      return '040';
    case 'iam':
      if (url.indexOf('email') >= 0) {
        return '051';
      }
      if (url.indexOf('username') >= 0) {
        return '052';
      }
      return '050';
    case 'learning':
      if (url.indexOf('activities') >= 0) {
        return '061';
      }
      if (url.indexOf('activitygroups') >= 0) {
        return '062';
      }
      if (url.indexOf('assessmentresults') >= 0) {
        return '063';
      }
      if (url.indexOf('memberships') >= 0) {
        return '064';
      }
      if (url.indexOf('section') >= 0) {
        return '065';
      }
      if (url.indexOf('attendances') >= 0) {
        return '066';
      }
      if (url.indexOf('curriculum') >= 0) {
        return '067';
      }
      if (url.indexOf('program') >= 0) {
        return '068';
      }
      return '060';
    case 'organizations':
      if (url.indexOf('military') >= 0) {
        return '072';
      }
      if (url.indexOf('veranorg') >= 0) {
        return '072';
      }
      return '070';
    case 'persons':
      if (url.indexOf('names') >= 0) {
        return '081';
      }
      if (url.indexOf('name') >= 0) {
        return '082';
      }
      if (url.indexOf('avatar') >= 0) {
        return '083';
      }
      if (url.indexOf('events-count') >= 0) {
        return '084';
      }
      if (url.indexOf('attributes') >= 0) {
        return '085';
      }
      if (url.indexOf('information') >= 0) {
        return '086';
      }
      if (url.indexOf('military') >= 0) {
        return '087';
      }
      if (url.indexOf('person/') >= 0) {
        return '088';
      }
      return '080';
    case 'preferences':
      return '090';
    case 'programs':
      if (url.indexOf('templates') >= 0) {
        return '101';
      }
      if (url.indexOf('programeligibility') >= 0) {
        return '102';
      }
      if (url.indexOf('v1/loareturndates') >= 0) {
        return '204';
      }
      return '100';
    case 'sagas':
      if (url.indexOf('persontasks') >= 0) {
        return '111';
      }
      if (url.indexOf('persontask') >= 0) {
        return '112';
      }
      if (url.indexOf('workflow/admission/person') >= 0) {
        return '113';
      }
      return '110';
    case 'student':
      if (url.indexOf('pla/documents') >= 0) {
        return '127';
      }
      if (url.indexOf('pla/applications') >= 0) {
        return '128';
      }
      if (url.indexOf('pla') >= 0) {
        return '121';
      }
      if (url.indexOf('detailprogression') >= 0) {
        return '122';
      }
      if (url.indexOf('statuses') >= 0) {
        return '123';
      }
      if (url.indexOf('membershipsdetails') >= 0) {
        return '124';
      }
      // Both loa and leaveofabsence paths are accounted for as 200
      // Will be addressed in future story
      if (url.indexOf('leaveofabsence') >= 0) {
        return '200';
      }
      if (url.indexOf('outcome') >= 0) {
        return '126';
      }
      if (url.indexOf('cbe') >= 0) {
        return '129';
      }
      if (url.indexOf('estimatedgraduationdate') >= 0) {
        return '191';
      }
      if (url.indexOf('course/memberships') >= 0) {
        return '192';
      }
      if (url.indexOf('academic/terms') >= 0) {
        return '193';
      }
      if (url.indexOf('academic/years') >= 0) {
        return '194';
      }
      if (url.indexOf('academic/yearterms') >= 0) {
        return '195';
      }
      if (url.indexOf('transfercreditdetails') >= 0) {
        return '196';
      }
      if (url.indexOf('loa/create') >= 0) {
        return '201';
      }
      if (url.indexOf('loa/history') >= 0) {
        return '202';
      }
      if (url.indexOf('loa/cancel') >= 0) {
        return '203';
      }
      return '120';
    case 'wallets':
      return '130';
    case 'communication':
      if (url.indexOf('notifications') >= 0) {
        return '141';
      }
      if (url.indexOf('alerts') >= 0) {
        return '142';
      }
      return '140';
    case 'document':
      if (url.indexOf('formorpacket') >= 0) {
        return '151';
      }
      return '150';
    case 'search':
      return '160';
    case 'vouchers':
      if (url.indexOf('voucher?personId') >= 0) {
        return '171';
      }
      if (url.endsWith('voucher') >= 0) {
        return '172';
      }
      return '170';
    case 'documents':
      if (url.indexOf('documents?') >= 0) {
        return '181';
      }
      return '180';
    // 200 series is for LOA related MS calls
    // internal next api
    case 'services':
      if (url.indexOf('get-kba-image') >= 0) {
        return '301';
      }
      if (url.indexOf('get-kba') >= 0) {
        return '302';
      }
      if (url.indexOf('get-token') >= 0) {
        return '303';
      }
      if (url.indexOf('post-feedback') >= 0) {
        return '304';
      }
      if (url.indexOf('post-kb-feedback') >= 0) {
        return '305';
      }
      if (url.indexOf('share-post') >= 0) {
        return '306';
      }
      return '300';
    // vendors
    case 'builder':
      if (url.indexOf('/api/v1/query') >= 0) {
        return '501';
      }
      return '500';
    default:
      return '000';
  }
};

const urlToCode = (url) => {
  const domain = getDomain(url);
  return `${getHostNameToDigits(url)}${domainToDigits(domain, url)}`;
};

export const getErrorCode = (httpVerb, requestUrl = '', responseStatusCode = '000') => `${methodToCode(httpVerb?.toLowerCase())}-${urlToCode(requestUrl.toLowerCase())}-${responseStatusCode}`;

export const errorToCode = (response, requestConfig) => {
  const errorCode = `${methodToCode(requestConfig?.method?.toLowerCase())}-${urlToCode(requestConfig?.url ? requestConfig?.url?.toLowerCase() : '')}-${response?.status || '000'}`;
  return errorCode;
};

export const errorToMessage = (error) => {
  const { code = '' } = error;
  const errorCode = code || errorToCode(error);
  if (errorCode === '00-00000-000' && error.message) {
    return error.message;
  }
  return errorCode;
};

export const trackTimeoutError = (requestCode, requestId, correlationId) => {
  publishMyPhoenixErrorEvent(`ECONNABORTED: ${requestCode}`, { personId: getPersonIdFromSessionStorage() || 'N/A', requestId, correlationId });
};

export const trackComponentError = (component, message) => {
  publishMyPhoenixErrorEvent(`ErrorMessage: ${component}`, { personId: getPersonIdFromSessionStorage() || 'N/A', message });
};

export const reloadOnError = () => {
  const { purgeOnErrorReloadEnabled } = features;
  if (purgeOnErrorReloadEnabled) {
    // window.MyPhoenix.Persistor.purge();
    // TODO: Add Persistor into project
  }
  window.location.reload();
};
