import BaseService from '@/services/wrappers/service';

export default class VouchersService extends BaseService {
  constructor(client) {
    super(client, 'vouchers');
    this.postVouchers = this.postVouchers.bind(this);
    this.getVouchersByPersonid = this.getVouchersByPersonid.bind(this);
  }

  async getVouchersByPersonid(personId) {
    return this.client.get(`${this.baseEndpointUrl}/v1/voucher?personId=${personId}`);
  }

  async postVouchers(voucher) {
    return this.client.post(
      `${this.baseEndpointUrl}/v1/voucher`,
      voucher,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }
}
