import '@/styles/globals.css';
import type { ReactElement, ReactNode } from 'react';
import { PropsWithChildren } from 'react';
import { Provider } from 'react-redux';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { SessionProvider } from 'next-auth/react';
import { builder } from '@builder.io/react';
import type { ApiVersion as BuilderApiVersion } from '@builder.io/sdk';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider } from '@emotion/react';
import DefaultLayout from '@/pages/_layout';
import { wrapper } from '@/store';
import createEmotionCache from '@/helpers/emotion/createEmotionCache';
import ReactTracker from '@/hoc/react-tracker';
// TODO: Revert back to SSR component once styles are fixed
import AuthRedirect from '@/hoc/AuthRedirectConditionalSsr';
import { GoogleTagManagerScript } from '@/components/atoms/vendor/google-tag-manager';
import { GoogleDataLayer } from '@/components/atoms/vendor/google-data-layer';
import { withFeatureProvider, FeatureProvider } from '@/helpers/feature-provider';
import ErrorBoundary from '@/components/organisms/error-boundary';
import PageErrorBoundary from '@/components/organisms/page-error-boundary';
import config from '@/helpers/config';

export type MyPhxLayoutProps = PropsWithChildren<{
  title?: string,
}>;

export type MyPhxLayout = (props: MyPhxLayoutProps) => ReactElement;

export type MyPhxNextPage<P = {}, IP = P> = NextPage<P, IP> & {
  layout?: MyPhxLayout,
  getLayout?: (page: ReactElement) => ReactNode,
  pageTitle?: string,
  requiresAuthentication?: boolean,
};

export type MyPhxAppProps = AppProps & {
  Component: MyPhxNextPage,
  emotionCache?: any,
  pageProps?: any,
};

const { Builder } = config;
// ! IMPORTANT for GDPR and CCPA compliance (Pending listener to enable) canTrack has to be false
// ! DO NOT REMOVE THIS IS FOR INSPECTION
// This is a public key meant to be visible to the client to pull non sensitive content
builder.init(Builder.apiKey, false, null, null, null, Builder.apiVersion as BuilderApiVersion);

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const EmptyComponent = ({ children }: { children: ReactElement }) => children;

function MyApp(props: MyPhxAppProps) {
  const { store } = wrapper.useWrappedStore(props);

  const {
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps: { session, ...pageProps },
  } = props;

  const {
    pageTitle,
    getLayout: overrideGetLayout,
    layout: OverrideLayout,
  } = Component;

  const getLayout = overrideGetLayout
    || (OverrideLayout ? (
      children: ReactElement,
      title: string,
    ) => <OverrideLayout title={title}>{children}</OverrideLayout>
      : (
        children: ReactElement,
        title: string,
      ) => <DefaultLayout title={title}>{children}</DefaultLayout>);

  const AuthWrapper = ((Component.requiresAuthentication ?? true) && !builder.editingMode)
    ? AuthRedirect : EmptyComponent;

  return (
    <Provider store={store}>
      <CacheProvider value={emotionCache}>
        <Head>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Head>
        <GoogleTagManagerScript />
        <PageErrorBoundary>
          <SessionProvider session={session} refetchInterval={15 * 60}>
            <AuthWrapper>
              <>
                <GoogleDataLayer personId={session?.user?.personId} />
                <FeatureProvider personId={session?.user?.personId} />
                <ReactTracker>
                  {getLayout(
                    <>
                      <CssBaseline />
                      <ErrorBoundary>
                        <Component {...pageProps} />
                      </ErrorBoundary>
                    </>,
                    pageTitle,
                  )}
                </ReactTracker>
              </>
            </AuthWrapper>
          </SessionProvider>
        </PageErrorBoundary>
      </CacheProvider>
    </Provider>
  );
}

export default withFeatureProvider(MyApp);
