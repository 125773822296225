import { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {
  FooterContainer,
  IconLinkBox,
  LinkListItem,
  LinkListBox,
  FootNoteSpan,
} from '@/components/organisms/footer/Footer.styles';
import IconLink from '@/components/molecules/icon-link';
import IconTile from '@/components/molecules/icon-tile';
import Accordion from '@/components/molecules/accordion';
import LinkList from '@/components/molecules/link-list';
import CenteredLogo from '@/components/molecules/centered-logo';
import makeAbsoluteUrl from '@/helpers/absolute-url';
import { useClickEventTracking } from '@/hooks/useClickEventTracking';
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import useGetProgramsByIdHook from '@/store/hooks/useGetProgramsByIdHook';
import { useGetCopyrightTextQuery } from '@/store/queries/builder.io';
import { getClassUrl } from '@/myphoenix/utils/classroom-functions';
import { useGetPersonsQuery } from '@/store/queries/person';
import { formatUserDefinedAttributes } from '@/helpers/link-detokenizer';
import StandardLink from '@/components/atoms/standard-link';
import features from '@/helpers/features';
import constants from './constants';

const { canaryTextEnabled } = features;

function CopyrightLink({ componentName, link, last, trackClick }) {
  return (
    <Box component="span" key={link.text}>
      <StandardLink
        href={link.url}
        underline="hover"
        id={link.id}
        onClick={() => {
          trackClick(
            `${componentName}_CopyrightLink`,
            [{ url: link.url }, { target: link.target }, { text: link.text }],
          );
        }}
      >
        <FootNoteSpan
          variant="body6"
          component="span"
          alwaysUnderlined={link.alwaysUnderlined}
        >
          {link.text}
        </FootNoteSpan>
      </StandardLink>
      <Typography variant="body6" component="span">{`${(last ? '' : ' | ')}`}</Typography>
    </Box>
  );
}

function BetaText() {
  return (
    <Box component="span">
      <FootNoteSpan
        variant="body6"
        component="span"
        alwaysUnderlined={false}
      >
        Beta (ECS)
      </FootNoteSpan>
    </Box>
  );
}

function FootNote({ componentName, copyrightText = constants.copyrightText }) {
  const theme = useTheme();
  const trackClick = useClickEventTracking();
  return (
    <Grid
      container
      direction="column"
      alignItems={{ xs: 'baseline', sm: 'center' }}
      pl={theme.spacing(1.5)}
      mt={theme.spacing(2)}
      textAlign={{ xs: 'left', sm: 'center' }}
    >
      <Grid item pb={1}>
        <CenteredLogo
          id="phoenix-logo"
          parentId="footer"
          version="footer"
          href={makeAbsoluteUrl('/home.html')}
          parentComponentName={componentName}
        />
      </Grid>
      <Grid item>
        {constants.copyrightLinks.map((link, index) => (
          <CopyrightLink
            key={link.url}
            componentName={componentName}
            link={link}
            last={index === constants.copyrightLinks.length - 1 && !canaryTextEnabled}
            trackClick={trackClick}
          />
        ))}
        {canaryTextEnabled && <BetaText />}
      </Grid>
      <Grid item>
        <FootNoteSpan
          component="span"
        >
          {copyrightText}
        </FootNoteSpan>
      </Grid>
    </Grid>
  );
}

function ListItem({ componentName, item, index }) {
  return (
    <Grid item key={item.linkList}>
      <Box display={{ xs: 'block', sm: 'none' }}>
        <Accordion
          header={item.linkList}
          links={item.links}
          styleType="default"
          sectionIndex={index}
          parentId="footer"
        />
      </Box>
      <LinkListBox
        display={{ xs: 'none', sm: 'flex' }}
      >
        <LinkList
          parentComponentName={componentName}
          header={item.linkList}
          links={item.links}
          styleType="footer"
          footer
        />
      </LinkListBox>
    </Grid>
  );
}

function Footer() {
  const componentName = 'Footer';
  const personId = getPersonIdFromSessionStorage();
  const { data: { copyright: copyrightText } = {} } = useGetCopyrightTextQuery();
  const {
    data: { primaryProgram } = {},
  } = useGetProgramsByIdHook(personId);
  const { data: { userDefined: userDefinedAttributes } = {} } = useGetPersonsQuery(
    { personId },
    { skip: !personId },
  );

  const linkListItems = useMemo(() => {
    const primaryProgramId = primaryProgram?.id ? [primaryProgram.id] : [];
    const primaryProgramCode = primaryProgram?.code ? [primaryProgram.code] : [];
    const qualificationLevel = primaryProgram?.qualificationLevel
      ? [primaryProgram.qualificationLevel] : [];
    const definedAttributes = userDefinedAttributes || [];

    const attributes = ['all'].concat(definedAttributes, qualificationLevel, primaryProgramCode);

    const userGroups = [...(formatUserDefinedAttributes(attributes)), ...primaryProgramId];

    const remappedLinks = constants.linkLists.map((list) => {
      const parsedLinks = list.links.reduce((prev, curr) => {
        if (!curr?.group || userGroups?.some((key) => curr?.group[key])) return [...prev, curr];
        return prev;
      }, []);

      return { ...list, links: parsedLinks };
    });
    return remappedLinks;
  }, [primaryProgram, userDefinedAttributes]);

  const tileItems = constants.tiles.map((item, index) => {
    let { url } = item;

    // TODO: Authorable link
    if (url === constants.tiles[0].url) {
      url = getClassUrl(primaryProgram?.cbeDaProgram === 'TRUE');
    }

    return (
      <Grid item key={item.icon}>
        <Box display={{ xs: 'none', sm: 'flex' }}>
          <IconTile
            id={`footer-tile-icon-${index}`}
            name={`footer-tile-${index}`}
            icon={item.icon}
            text={item.text}
            url={url}
            target={item.target}
            parentComponentName={componentName}
          />
        </Box>
        <IconLinkBox display={{ xs: 'flex', sm: 'none' }}>
          <IconLink
            id={`footer-link-icon-${index}`}
            name={`footer-tile-${index}`}
            icon={item.icon}
            text={item.text}
            url={url}
            styleType="footer"
            target={item.target}
            parentComponentName={componentName}
          />
        </IconLinkBox>
      </Grid>
    );
  });

  return (
    <FooterContainer
      container
      justifyContent="center"
      component="footer"
      alignItems={{ xs: 'start', sm: 'center' }}
    >
      <Grid item>
        <Grid
          container
          direction={{ xs: 'column', sm: 'row' }}
        >
          {tileItems}
        </Grid>
      </Grid>
      <LinkListItem item>
        <Grid
          container
          justifyContent="space-between"
          direction={{ xs: 'column', sm: 'row' }}
        >
          {
            linkListItems.map((item, index) => (
              <ListItem
                componentName={componentName}
                item={item}
                index={index}
                key={item.linkList}
              />
            ))
          }
        </Grid>
      </LinkListItem>
      <Grid item>
        <FootNote
          componentName={componentName}
          copyrightText={copyrightText}
        />
      </Grid>
    </FooterContainer>
  );
}

export default Footer;
