import { QueryReturnValue } from '@reduxjs/toolkit/src/query/baseQueryTypes';
import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query';
import { FetchArgs } from '@reduxjs/toolkit/dist/query/react';
import Config from '@/helpers/config';
import { trackComponentError } from '@/myphoenix/utils/error-functions';

export const getInvalidParamsFromUrl = (url: string) => {
  const params = url?.startsWith('/') ? new URL(`${Config.myphx.baseUrl}${url}`)?.searchParams : new URL(url)?.searchParams;
  let invalidParams = '';
  if (params) {
    params.forEach((value, key) => {
      if (!value || value === 'undefined') {
        invalidParams = `${invalidParams.length ? `${invalidParams},` : ''}${key}`;
      }
    });
  }
  return invalidParams;
};

export const logAndPublishError = (message: string) => {
  const utilName = 'prefetchValidationUtil';
  // we want a console error for this so we know if we have a missing skip in a query
  // eslint-disable-next-line no-console
  console.error(`${utilName} : `, message);
  trackComponentError(utilName, message);
};

export const getPreFetchData = (args: FetchArgs):
QueryReturnValue<null, FetchBaseQueryError, FetchBaseQueryMeta> => {
  const isValid = {
    error: null,
  } as QueryReturnValue<null, FetchBaseQueryError, FetchBaseQueryMeta>;

  const { url } = args;
  if (!url) {
    const errorMessage = 'Refused to fetch due to missing url';
    logAndPublishError(errorMessage);
    return {
      error: {
        status: 0,
        data: errorMessage,
      },
    } as QueryReturnValue<null, FetchBaseQueryError, FetchBaseQueryMeta>;
  }

  const invalidParams = getInvalidParamsFromUrl(url);

  if (invalidParams) {
    const errorMessage = `Refused fetch to ${url} due to missing parameters - ${invalidParams}`;
    logAndPublishError(errorMessage);
    return {
      error: {
        status: 0,
        data: errorMessage,
      },
    } as QueryReturnValue<null, FetchBaseQueryError, FetchBaseQueryMeta>;
  }

  // todo add check for request body, for things like cbe anticipated drop date or puts or posts

  return isValid;
};
