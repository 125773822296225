import { useEffect, useRef, useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import HeaderAvatar from '@/components/molecules/header-avatar';
import { AppBar, Banner, Toolbar } from '@/components/organisms/header/v1/Header.styles';
import HeaderNotification from '@/components/molecules/header-notification';
import IconLink from '@/components/molecules/icon-link';
import CenteredLogo from '@/components/molecules/centered-logo';
import SiteStripe from '@/components/molecules/site-stripe';
import HamburgerMenu from '@/components/molecules/hamburger-menu';
import HamburgerMenuDropdown from '@/components/molecules/hamburger-menu/hamburger-menu-dropdown';
import { useGetAlertsQuery } from '@/store/queries/alerts';
import { useGetPersonsQuery } from '@/store/queries/person';
import useGetProgramsByIdHook from '@/store/hooks/useGetProgramsByIdHook';
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import makeAbsoluteUrl from '@/helpers/absolute-url';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import { useGetBuilderDataModelByNameQuery, useGetSiteStripeDataQuery } from '@/store/queries/builder.io';
import { HEADER } from '@/helpers/builder/models';
import Config from '@/helpers/config';

function useOutsideClick(wrapperRef, buttonRef, closeMenu) {
  useEffect(() => {
    function handleOutsideClick(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)
        && buttonRef.current && !buttonRef.current.contains(event.target)) {
        closeMenu();
      }
    }

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [wrapperRef, buttonRef, closeMenu]);
}

export const ComponentName = 'Header';

function Header() {
  const personId = getPersonIdFromSessionStorage();
  const [menuOpen, setMenuOpen] = useState(false);

  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }
  function closeMenu() {
    setMenuOpen(false);
  }

  const { data: { alerts } = {} } = useGetAlertsQuery(
    { personId },
    { skip: !personId },
  );

  const { data: { personEmail } = {}, data: { userDefined } = {} } = useGetPersonsQuery(
    { personId },
    { skip: !personId },
  );

  const {
    data: { primaryProgram } = {},
  } = useGetProgramsByIdHook(personId);

  const {
    data: { message, displayFlag, ctaText, ctaUrl } = {},
  } = useGetSiteStripeDataQuery();

  const {
    data: { avatarUrl } = { avatarUrl: '/profile' },
  } = useGetBuilderDataModelByNameQuery(
    { modelName: HEADER },
  );

  const wrapperRef = useRef(null);
  const hamburgerButtonRef = useRef(null);
  useOutsideClick(wrapperRef, hamburgerButtonRef, closeMenu);

  const { office365 } = Config;

  return (
    <AppBar
      data-component={componentNameToMyPhxKebabFormat(ComponentName)}
      position="sticky"
    >
      <Banner>
        <HeaderAvatar
          personId={personId || ''}
          profileLink={avatarUrl}
          parentId="banner"
          parentComponentName={ComponentName}
        />
      </Banner>
      <Toolbar disableGutters>
        <Container maxWidth="md">
          <Grid container>
            <Grid
              item
              xs={12}
              sm={5}
              display={{ xs: 'none', sm: 'block' }}
            >
              <Grid container>
                <Grid item>
                  <HeaderAvatar
                    personId={personId || ''}
                    profileLink={avatarUrl}
                    parentId="header"
                    parentComponentName={ComponentName}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} sm={4}>
              <Grid container>
                <Grid item sx={{ pt: 0.7 }}>
                  <CenteredLogo
                    href={makeAbsoluteUrl('/home.html')}
                    id="phoenix-logo"
                    parentId="header"
                    version="header"
                    parentComponentName={ComponentName}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={10}
              sm={3}
            >
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                wrap="nowrap"
                sx={{ marginLeft: '0px', paddingRight: { xs: 2, sm: 0 } }}
              >
                <Grid item>
                  <IconLink
                    styleType="header"
                    url={`https://outlook.office365.com/owa/?realm=${office365?.emailRealm}`}
                    id="header-icon-email"
                    icon="icon-email"
                    text="Email Inbox"
                    parentComponentName="header"
                  />
                </Grid>
                <Grid item>
                  <HeaderNotification
                    parentComponentName={ComponentName}
                  />
                </Grid>
                <Grid item>
                  <HamburgerMenu
                    toggleMenu={() => toggleMenu()}
                    open={menuOpen}
                    forwardRef={hamburgerButtonRef}
                    parentComponentName={ComponentName}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
      <Container
        maxWidth="md"
        data="container2"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          width: '100%',
          padding: 0,
        }}
      >
        <HamburgerMenuDropdown
          menuOpen={menuOpen}
          onClick={(e) => {
            e.preventDefault();
            toggleMenu();
          }}
          emailAddress={personEmail}
          userDefinedAttributes={userDefined}
          program={primaryProgram}
          forwardRef={wrapperRef}
          personId={getPersonIdFromSessionStorage()}
        />
      </Container>
      <SiteStripe
        siteStripeAlerts={alerts}
        siteStripeOn={displayFlag}
        message={message}
        ctaText={ctaText}
        ctaUrl={ctaUrl}
        parentComponentName={ComponentName}
      />
    </AppBar>
  );
}

export default Header;
