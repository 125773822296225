// TODO: In order to use this we would have to update each MS to allow this header for CORS
const retryCountHeaderName = 'X-Conflict-Retry-Attempt';
const etagHeaderName = 'etag';
const statusCode = 409;
const maxRetries = 2;
const retryMethods = ['put', 'patch', 'delete'];

const resourceConflictInterceptor = (response, requestConfig, client) => {
  const retryAttempts = response.headers.get(retryCountHeaderName) || 0;
  if ((retryMethods.some((method) => method === requestConfig.method.toLowerCase()))
    && response.status === statusCode
    && retryAttempts < maxRetries) {
    const reRequestConfig = {
      ...requestConfig,
      headers: {
        ...requestConfig?.headers,
        [retryCountHeaderName]: retryAttempts + 1,
        'If-Match': response.headers.get(etagHeaderName),
      },
    };
    return client.request(reRequestConfig);
  }
  return null;
};

export default resourceConflictInterceptor;
