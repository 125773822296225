import Config from '@/helpers/config';
import { LinkTarget } from '@/types/linkTarget';

const { myphx: { baseUrl } = {} } = Config;

const multimediaPath = '//multimedia.phoenix.edu';
const myphxKbArticleBasePath = '/kb/';
const myphxPublicBasePath = '/public/';
const myphxFinancialPlanPath = '/financial-plan';

const externalPathExceptions = [
  multimediaPath,
];
const relativeInternalPathExceptions = [
  myphxKbArticleBasePath,
  myphxPublicBasePath,
  myphxFinancialPlanPath,
];
const absoluteInternalPathExceptions = [
  `${baseUrl}${myphxKbArticleBasePath}`,
  `${baseUrl}${myphxPublicBasePath}`,
  `${baseUrl}${myphxFinancialPlanPath}`,
];

const getTargetForExternalPaths = (url: string) => {
  if (url && externalPathExceptions.some((str) => url.includes(str))) {
    return '_self';
  }
  return '_blank';
};

const getTargetForRelativePaths = (url: string) => {
  if (relativeInternalPathExceptions.some((str) => url.includes(str))) {
    return '_blank';
  }
  return '_self';
};

const getTargetForAbsolutePaths = (url: string) => {
  if (absoluteInternalPathExceptions.some((str) => url.includes(str))) {
    return '_blank';
  }
  return '_self';
};

const getLinkProperties = (url: string, targetOverride?: LinkTarget):
{ url: string, target: LinkTarget } => {
  // console.log({ url, targetOverride });
  if (url?.includes(baseUrl)) {
    return {
      target: targetOverride || getTargetForAbsolutePaths(url),
      url,
    };
  }

  if (url?.startsWith('/')) {
    return {
      target: targetOverride || getTargetForRelativePaths(url),
      url: baseUrl + url,
    };
  }

  if (url?.startsWith('#')) {
    return {
      target: targetOverride || getTargetForRelativePaths(url),
      url,
    };
  }

  if (url?.startsWith('tel:') || url?.startsWith('mailto:')) {
    return {
      target: targetOverride || '_self',
      url,
    };
  }

  return {
    target: targetOverride || getTargetForExternalPaths(url),
    url,
  };
};

export default getLinkProperties;
