import { setItem, removeItem } from '.';

export const DATE_OVERRIDE_KEY = 'myphx-override-current-date';

export function setCurrentDate(dateISOString?: string):void {
  if (!dateISOString) {
    removeItem(DATE_OVERRIDE_KEY);
  } else {
    setItem(DATE_OVERRIDE_KEY, dateISOString);
  }
}
