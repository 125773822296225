import { styled } from '@mui/material/styles';
import { Badge } from '@mui/material';
import StandardLink from '@/components/atoms/standard-link';
import { bellSwayAnimation, bellClapperSwayAnimation } from './NotificationBell.keyframes';

export const BadgeContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
}));

export const NotificationBadge = styled(Badge)(({ theme }) => ({
  width: theme.spacing(3.2),
  height: theme.spacing(3.2),
  '& .MuiBadge-dot': {
    height: theme.spacing(1.7),
    width: theme.spacing(1.7),
    borderRadius: '100%',
  },
  '& .MuiBadge-anchorOriginTopRightCircular': {
    border: `${theme.spacing(0.2)} ${theme.palette.common.white} solid`,
    top: '18%',
    right: '23%',
  },
  '& .BaseBadge-invisible': {
    display: 'none',
  },
}));

export const Bell = styled('div')(({ theme }) => ({
  position: 'relative',
  top: theme.spacing(2),
  left: theme.spacing(1.6),
  transform: 'translate(-50%, -50%)',
}));

export const BellTop = styled('div', { shouldForwardProp: false })(({ theme, animate = false }) => ({
  width: theme.spacing(1.6),
  height: theme.spacing(1.6),
  background: theme.palette.common.black,
  borderRadius: '50% 50% 0 0',
  '&:before': {
    content: '""',
    position: 'absolute',
    width: theme.spacing(0.4),
    height: theme.spacing(0.4),
    background: 'inherit',
    top: theme.spacing(-0.2),
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: '50%',
  },
  '&:after': {
    content: '""',
    borderBottom: `${theme.spacing(0.4)} solid ${theme.palette.common.black}`,
    borderLeft: `${theme.spacing(0.4)} solid ${theme.palette.common.white}`,
    borderRight: `${theme.spacing(0.4)} solid ${theme.palette.common.white}`,
    height: '0',
    width: theme.spacing(2.4),
    position: 'absolute',
    top: theme.spacing(1.6),
    left: theme.spacing(-0.4),
  },
  ...(animate ? { animation: `${bellSwayAnimation} 1000ms ease-in-out` } : {}),
  transformOrigin: 'top',
}));

export const BellBottom = styled('div', { shouldForwardProp: false })(({ theme, animate = false }) => ({
  width: theme.spacing(0.6),
  height: theme.spacing(0.3),
  background: '#000',
  position: 'absolute',
  top: theme.spacing(2.1),
  left: '50%',
  transform: 'translateX(-50%)',
  borderRadius: `0 0 ${theme.spacing(0.3)} ${theme.spacing(0.3)}`,
  ...(animate ? { animation: `${bellClapperSwayAnimation} 1000ms ease-in-out` } : {}),
}));

export const NotificationBellLabel = styled('div')(({ theme }) => ({
  fontSize: theme.spacing(1.4),
  lineHeight: theme.spacing(1.6),
  textDecoration: 'none',
}));

export const NotificationBellLink = styled(StandardLink)(() => ({
  textDecoration: 'none',
  color: '#000',
  '&:hover, &:focus': {
    textDecoration: 'underline #db3725',
  },
}));
