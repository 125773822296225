import { styled } from '@mui/material/styles';
import { withTransientProps } from '@/helpers/with-transient-props';
import StandardLink from '@/components/atoms/standard-link';
import Icon from '@/components/atoms/icon';

type HoverLinkProps = {
  $cta: boolean;
};

export const HoverLink = styled(
  StandardLink,
  withTransientProps,
)<HoverLinkProps>(({ theme, $cta }) => ({
  color: theme.palette.primary.main,
  fontSize: theme.spacing(1.6),
  lineHeight: theme.spacing(1.6),
  whiteSpace: 'nowrap',
  textDecoration: 'none',
  ...($cta
    ? {
      '& svg': {
        transition: 'transform 0.2s linear',
      },
      '&:focus': {
        outline: `${theme.spacing(0.1)} solid ${theme.palette.text.primary}`,
        outlineOffset: theme.spacing(0.3),
      },
      '&:hover svg': {
        transform: `translate(${theme.spacing(0.4)}, 0)`,
        fill: theme.palette.primary.dark,
      },
      '&:hover': {
        color: theme.palette.primary.dark,
        '& span': {
          color: theme.palette.primary.dark,
        },
      },
      '&:active': {
        color: theme.palette.primary.d60,
        '& span': {
          color: theme.palette.primary.d60,
        },
        '& svg': {
          fill: theme.palette.primary.d60,
        },
      },
      '& span': {
        color: theme.palette.primary.main,
      },
    } : {
      color: theme.palette.secondary.main,
      '& svg': {
        transition: 'transform 0.2s linear',
        fill: theme.palette.secondary.main,
      },
      '&:hover svg': {
        transform: `translate(${theme.spacing(0.4)}, 0)`,
        fill: theme.palette.secondary.dark,
      },
      '&:hover': {
        color: theme.palette.secondary.dark,
        '& span': {
          color: theme.palette.secondary.dark,
        },
      },
      '&:active': {
        color: theme.palette.secondary.d60,
        '& span': {
          color: theme.palette.secondary.d60,
        },
        '& svg': {
          fill: theme.palette.secondary.d60,
        },
      },
      '& span': {
        color: theme.palette.secondary.main,
      },
    }),
}));

export const StyledIcon = styled(Icon)(({ theme }) => ({
  fill: theme.palette.primary.main,
  paddingTop: theme.spacing(0.08),
}));
