import { proxyClientInstance as apiClientInstance } from './wrappers';
import PersonsService from './domains/persons/v1';
import SearchService from './domains/search/v1';
import ProgramsServiceV1 from './domains/programs/v1';
import ProgramsService from './domains/programs/v3';
import LearningService from './domains/learning/v1';
import ContactsService from './domains/contacts/v1';
import SagasService from './domains/sagas/v1';
import StudentService from './domains/student/v1';
import StudentServiceV2 from './domains/student/v2';
import CommunicationService from './domains/communication/v1';
import CoursesService from './domains/courses/v1';
import DocumentService from './domains/document/v1';
import FeedbackSurveyService from './domains/surveys/v1';
import ApplicationService from './domains/applications/v1';
import VouchersService from './domains/vouchers/v1';
import DocumentsService from './domains/documents/v1';
import MockService from './domains/mock/v1';

const services = {
  ApplicationService: new ApplicationService(apiClientInstance),
  PersonsService: new PersonsService(apiClientInstance),
  SearchService: new SearchService(apiClientInstance),
  LearningService: new LearningService(apiClientInstance),
  ContactsService: new ContactsService(apiClientInstance),
  SagasService: new SagasService(apiClientInstance),
  StudentService: new StudentService(apiClientInstance),
  StudentServiceV2: new StudentServiceV2(apiClientInstance),
  ProgramsServiceV1: new ProgramsServiceV1(apiClientInstance),
  ProgramsService: new ProgramsService(apiClientInstance),
  CommunicationService: new CommunicationService(apiClientInstance),
  CoursesService: new CoursesService(apiClientInstance),
  DocumentService: new DocumentService(apiClientInstance),
  FeedbackSurveyService: new FeedbackSurveyService(apiClientInstance),
  VouchersService: new VouchersService(apiClientInstance),
  DocumentsService: new DocumentsService(apiClientInstance),
  MockService: new MockService(apiClientInstance),
};

export type Services = typeof services;
export type Client = typeof apiClientInstance;

export default services;
