import { Services } from '@/services';
import { baseApi, isQueryFnErrorResponse } from '@/store/queries';

export const applicationApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getApplicationInfoById: build.query({
      async queryFn(
        { applicationId, token }:
        { applicationId: string, token?: string },
        { extra },
      ) {
        const { ApplicationService } = extra as Services;
        try {
          const result = await ApplicationService.setToken(token)
            .setServerSideBaseUrl()
            .getApplicationInfoByApplicationId(applicationId);
          return { data: result?.data };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
    }),
  }),
});

export const {
  useGetApplicationInfoByIdQuery,
} = applicationApi;
