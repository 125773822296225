import { styled } from '@mui/material/styles';
import { Typography, Grid, Box } from '@mui/material';

export const FooterContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.almostBlack.l90,
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(6),
  width: '100%',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(3),
  },
}));

export const IconLinkBox = styled(Box)(({ theme }) => ({
  paddingInlineStart: theme.spacing(1),
  paddingBottom: theme.spacing(3),
}));

export const LinkListItem = styled(Grid)(({ theme }) => ({
  width: '100%',
  maxWidth: '106.6rem',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(3),
  },
}));

export const LinkListBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  width: theme.spacing(20),
  [theme.breakpoints.between('sm', 'md')]: {
    width: theme.spacing(15),
  },
}));

export const FootNoteSpan = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'alwaysUnderlined',
})(({ theme, alwaysUnderlined }) => ({
  color: theme.palette.text.primary,
  fontWeight: 400,
  [theme.breakpoints.up('xs')]: {
    fontSize: theme.spacing(1.4),
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.spacing(1.2),
  },
  ...(alwaysUnderlined && {
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  }),
}));
