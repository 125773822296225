import { Component } from 'react';
import { trackComponentError } from '@/myphoenix/utils/error-functions';
import ErrorBoundaryUi from './ErrorBoundary.ui';

type Props = {
  children?: any
};

type State = {
  hasError: boolean
};

export const ComponentName = 'myphoenix-next-client-error';

class ErrorBoundary extends Component<Props, State> {
  constructor(props: unknown) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: unknown) {
    // Update state so the next render will show the fallback UI
    trackComponentError(ComponentName, { error });

    return { hasError: true };
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    // You can use your own error logging service here
    trackComponentError(ComponentName, { error, errorInfo });
  }

  render() {
    // Check if the error is thrown
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state?.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorBoundaryUi componentName={ComponentName} />
      );
    }

    // Return children components in case of no error
    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}

export default ErrorBoundary;
