const microservicesBase = process?.env?.NEXT_PUBLIC_MICROSERVICES_BASE || '';

export const contactsEndpoints = {
  getEmailsByPersonId: (
    personId: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/contacts/v1/emails?linkId=${personId}&linkType=person`,
  getPhonesByPersonId: (
    personId: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/contacts/v1/phones?linkId=${personId}&linkType=person`,
  getSupportContactsByPersonIdAndProgramCode: (
    personId: string,
    programCode: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/contacts/v2/support/${personId}?academicprogramcode=${programCode}`,
};

export default contactsEndpoints;
