import { styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

export interface StyledBoxWithOpenProps extends BoxProps {
  open?: boolean
}

export const NavContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open',
})<StyledBoxWithOpenProps>(({ theme, open }) => ({
  position: 'absolute',
  top: theme.spacing(8.2),
  transform: open ? 'scale(1, 1)' : 'translate(0, -100%)',
  transition: 'transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1)',
  zIndex: 2,
  color: theme.palette.text.primary,
}));

export const MenuOpenOverlay = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open',
})<StyledBoxWithOpenProps>(({ theme, open }) => ({
  transform: open ? 'scale(1, 1)' : 'translate(0, -100%)',
  position: 'fixed',
  top: theme.spacing(8.2),
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1,
  [theme.breakpoints.up('sm')]: {
    backgroundColor: theme.palette.common.black,
    opacity: 0.3,
    visibility: 'visible',
    transition: '(all 0.5s)',
  },
}));

export const NavCenterer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open',
})<StyledBoxWithOpenProps>(({ theme, open }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  maxWidth: theme.spacing(106.6),
  background: theme.palette.common.white,
  boxShadow: `inset 0 ${theme.spacing(0.1)} ${theme.spacing(0.2)} rgba(0, 0, 0, 0.2)`,
  '& nav': {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '90vh',
    width: theme.spacing(30),
    overflowY: 'scroll',
    padding: theme.spacing(4.5, 3, 2, 3),
    boxShadow: open ? `${theme.spacing(0, 0.3, 3.5, 0.3)} rgba(0, 0, 0, 0.5)` : 'none',
    [theme.breakpoints.up('md')]: {
      overflowY: 'auto',
      height: 'auto',
    },
  },
}));

export const LinkListsContainer = styled(Box)(({ theme }) => ({
  '& h2': {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: theme.spacing(1.5),
    margin: '0',
    lineHeight: theme.spacing(1.5),
  },
  '& ul': {
    margin: 0,
    padding: 0,
    '& li': {
      lineHeight: theme.spacing(1.5),
      listStyle: 'none',
      padding: `${theme.spacing(1.5)} 0 0 0`,
      '&:last-child': { paddingBottom: theme.spacing(3.5) },
    },
  },
  '& a': {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: theme.spacing(1.7),
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

export const IconLinksContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3.5),
  marginBottom: theme.spacing(3.5),
}));

export const IconContainer = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(2.0),
}));
