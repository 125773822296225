import { styled } from '@mui/material/styles';
import { Box, Grid, Typography } from '@mui/material';
import StandardLink from '@/components/atoms/standard-link';

export const CourseListsContainer = styled(Box)(({ theme }) => ({
  whiteSpace: 'nowrap',
  paddingBottom: theme.spacing(3.5),
  '& h2': {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: theme.spacing(1.5),
    margin: '0',
    lineHeight: theme.spacing(1.5),
  },
}));

export const CourseGridContainer = styled(Grid, { shouldForwardProp: (prop) => prop !== 'condensed' })(({ theme, condensed }) => ({
  height: condensed ? theme.spacing(5.8) : '100%',
  backgroundColor: theme.palette.almostBlack.l90,
  borderRadius: theme.spacing(0.5),
  marginBottom: theme.spacing(1),
  paddingTop: theme.spacing(1.5),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));

export const CourseCodeContainer = styled(StandardLink)(({ theme }) => ({
  marginRight: theme.spacing(1.5),
  marginLeft: theme.spacing(0.5),
  fontSize: theme.spacing(1.4),
  fontWeight: 600,
  position: 'relative',
  top: theme.spacing(-1.2),
  textDecoration: 'none',
  color: theme.palette.text.primary,
  textTransform: 'uppercase',
  borderBottom: `${theme.spacing(0.2)} solid ${theme.palette.primary.main}`,
  height: theme.spacing(1.8),
  '&:hover': {
    textDecoration: 'none',
    borderColor: theme.palette.text.primary,
  },
}));

export const CourseCodeContainerCondensed = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(1.6),
}));

export const CourseTitleContainer = styled(Typography, { shouldForwardProp: (prop) => prop !== 'condensed' })(({ theme, condensed }) => ({
  margin: 0,
  fontSize: theme.spacing(1.8),
  lineHeight: condensed ? theme.spacing(2.7) : theme.spacing(2.3),
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%',
  textTransform: 'uppercase',
}));

export const DirectAssessmentGridContainer = styled(Grid, { shouldForwardProp: (prop) => prop !== 'condensed' })(({ theme }) => ({
  whiteSpace: 'nowrap',
  height: '100%',
  backgroundColor: theme.palette.almostBlack.l90,
  borderRadius: theme.spacing(0.5),
  marginBottom: theme.spacing(1),
  paddingTop: theme.spacing(1.5),
  paddingLeft: theme.spacing(1),
}));
