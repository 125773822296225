import { baseApi } from '@/store/queries';

export const searchApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPortalSearch: build.query({
      async queryFn({
        pageNumber, pageSize, queryText, token, baseUrl,
      }, { extra: { SearchService } }) {
        const results = await SearchService.setToken(token)
          .setServerSideBaseUrl(baseUrl)
          .getSearchResults({ pageNumber, pageSize, queryText }, 'portal').catch((error) => ({ error }));

        if (results.error) {
          const { error: { response: { statusText, status } = {} } = {} } = results;
          return { error: { message: statusText || '', statusCode: status || 500 } };
        }

        return { data: results.data };
      },
    }),
    getStudentKbSearch: build.query({
      async queryFn({
        pageNumber, pageSize, queryText, baseUrl,
      }, { extra: { SearchService } }) {
        const results = await SearchService
          .setServerSideBaseUrl(baseUrl)
          .getSearchResults({ pageNumber, pageSize, queryText }, 'studentkb', { noAuth: true }).catch((error) => ({ error }));

        if (results.error) {
          const { error: { response: { statusText, status } = {} } = {} } = results;
          return { error: { message: statusText || '', statusCode: status || 500 } };
        }

        return { data: results.data };
      },
    }),
  }),
});

export const { useGetPortalSearchQuery, useGetStudentKbSearchQuery } = searchApi;
