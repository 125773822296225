const microservicesBase = process?.env?.NEXT_PUBLIC_MICROSERVICES_BASE || '';

export const learningEndpoints = {
  getSectionBySourceId: (
    sourceId: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/learning/v1/course/section/${sourceId}`,
  getMembershipsBySourceIdTypeAndRole: (
    sourceId: string,
    sourceType: string,
    roleType: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/learning/v1/course/memberships?$filter=sourceId eq '${sourceId}' and sourceType eq '${sourceType}' and roleType eq '${roleType}'`,
  getAttendancesByMembershipId: (
    membershipId: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/learning/v1/attendances?membershipId=${membershipId}`,
  getActivityGroupsBySectionId: (
    sourceId: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/learning/v1/course/activitygroups?sourceId=${sourceId}&sourceType=SECTION`,
  getActivitiesBySectionId: (
    sourceId: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/learning/v1/activities?sourceId=${sourceId}&sourceType=SECTION`,
};

export default learningEndpoints;
