import { v4 as uuidv4 } from 'uuid';
import { getItem, setItem } from '@/helpers/session-storage';

const xCorrelationIdName = 'X-Correlation-Id';
const xRequestIdName = 'X-Request-Id';
const xCorrelationId = getItem(xCorrelationIdName) || uuidv4();

// eslint-disable-next-line jest/require-hook
setItem(xCorrelationIdName, xCorrelationId);

export const getRequestId = (requestConfig) => (
  requestConfig && requestConfig.headers
    ? requestConfig.headers[xRequestIdName]
    : '');

export const getCorrelationId = (requestConfig) => (
  requestConfig && requestConfig.headers
    ? requestConfig.headers[xCorrelationIdName]
    : xCorrelationIdName);

export const trackingIdInterceptor = (requestConfig) => {
  const configUpdate = requestConfig;
  configUpdate.headers[xCorrelationIdName] = xCorrelationId;
  configUpdate.headers[xRequestIdName] = uuidv4();
  return configUpdate;
};
