import BaseService from '@/services/wrappers/service';

export default class DocumentsService extends BaseService {
  constructor(client) {
    super(client, 'documents');
    this.postDocuments = this.postDocuments.bind(this);
  }

  async postDocuments(doc, isCloud = false) {
    const formData = new FormData();
    formData.append('jsonDocInfoForPOST', JSON.stringify(doc));

    return this.client.post(
      `${this.baseEndpointUrl}/v1/documents?isCloud=${isCloud}`,
      formData,
      {
        headers: {
          Accept: 'application/json',
        },
        // taking off timeout limit for uploading files for now
        timeout: 0,
      },
    );
  }
}
