import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';
import { LinkTarget } from '@/types/linkTarget';
import { useClickEventTracking } from '@/hooks/useClickEventTracking';
import { StyledIcon, HoverLink } from './ArrowLink.styles';

type ArrowLinkProps = PropsWithChildren<{
  link: string,
  ariaLabel?: string,
  iconId?: string,
  onClick?: Function,
  target?: LinkTarget,
  cta?: boolean,
  clickEvent?: { componentName: string, properties?: { [key: string]: any }[] },
}>;

function ArrowLink({
  link,
  children,
  ariaLabel = null,
  iconId = 'icon-long-arrow',
  onClick = () => { },
  target = null,
  cta = true,
  clickEvent,
}: ArrowLinkProps) {
  const trackClick = useClickEventTracking();
  type AllowArray<T> = T | T[];
  const getNodeText = (node: AllowArray<React.ReactNode>): string => {
    if (['string', 'number'].includes(typeof node)) return node as unknown as string;
    if (node instanceof Array) return node.map(getNodeText).join('');
    if (typeof node === 'object' && (node as any).props?.children) return getNodeText((node as any).props?.children);
    return '';
  };
  return (
    <HoverLink
      href={link}
      aria-label={ariaLabel}
      $cta={cta}
      onClick={() => {
        if (clickEvent) {
          trackClick(
            `${clickEvent.componentName}_ArrowLink`,
            [
              ...(clickEvent.properties || []),
              { link },
              { target },
              { text: getNodeText(children) },
            ],
          );
        }
        onClick();
      }}
      targetOverride={target}
    >
      {children}
      <Box pl={1} component="span">
        <StyledIcon
          id={iconId}
          icon="icon-long-arrow"
          className="svg"
          aria-hidden
        />
      </Box>
    </HoverLink>
  );
}

export default ArrowLink;
