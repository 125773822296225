// eslint-disable-next-line jest/require-hook
let retryAttempts = 0;
const maxRetries = 2;
/**
 * This Interceptor is intended to handle server responses of 5XX.
*/
const retryInterceptor = (response, requestConfig, client) => {
  if (requestConfig && response
      && response.status >= 500
      && response.status <= 511
      && response.status !== 404) {
    // including this check because the detailprogression call has a 500 error we need to parse
    // remove this check once the microservice is fixed

    const isDegreeAuditPath = response?.url?.includes('/v1/program/detailprogression') || false;

    if (
      requestConfig?.method?.toLowerCase() === 'get'
          && response?.url
          && requestConfig?.headers
          && retryAttempts <= maxRetries
          && !isDegreeAuditPath
    ) {
      retryAttempts += 1;
      return client.request(requestConfig);
    }
  }

  retryAttempts = 0;
  return null;
};

export default retryInterceptor;
