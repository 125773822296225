import { Typography, Container } from '@mui/material';
import { StyledLink } from './ErrorBoundary.styles';

function ErrorBoundaryUi({
  componentName,
}: {
  componentName: string,
}) {
  return (
    <Container maxWidth="md" data-component={componentName}>
      <Typography variant="h3" component="h1" fontWeight="800" mt={6}>
        Looks like we ran into an issue.
      </Typography>
      <Typography variant="h4" component="h1">
        Let&apos;s get you to class:
      </Typography>
      <StyledLink href="https://d2l.phoenix.edu/d2l/lp/auth/saml/initiate-login?entityId=uopxBrightSpaceIDP">
        <Typography variant="body2" mt={2}>
          I&apos;m in a Direct Assessment program
        </Typography>
      </StyledLink>
      <StyledLink href="https://vle.phoenix.edu/">
        <Typography variant="body2" mt={2}>
          I&apos;m not in a Direct Assessment program
        </Typography>
      </StyledLink>
    </Container>
  );
}

export default ErrorBoundaryUi;
