import { baseApi, isQueryFnErrorResponse, isQueryFnExpandedErrorResponse } from '@/store/queries';
import { Services } from '@/services';

export const programsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getProgramProgressDataByProgramId: build.query({
      async queryFn(
        { programId, token },
        { extra },
      ) {
        try {
          const { StudentService } = extra as Services;
          const results = await StudentService.setToken(token)
            .setServerSideBaseUrl().getProgramProgressDataByProgramId(
              programId,
            );
          const creditsSummary = results?.data?.programProgress?.creditsSummary;
          return {
            data: {
              ...(results?.data || {}),
              isDegreeAudit: true,
              creditsSummary,
            },
          };
        } catch (error: unknown) {
          if (isQueryFnExpandedErrorResponse(error)) {
            const { response: { status, data: { message } = {} } = {} } = error;
            if (status === 500 && message.indexOf('Service is not available for program') > -1) {
              return { data: { isDegreeAudit: false } };
            }
          }
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: {
              message: statusText,
              statusCode: status,
            } };
          }
          throw error;
        }
      },
    }),
    getSkillsByProgramId: build.query({
      async queryFn(
        { programId, version, token }: { programId: string, version: string, token?: string },
        { extra },
      ) {
        try {
          const { LearningService } = extra as Services;
          const results = await LearningService.setToken(token)
            .setServerSideBaseUrl()
            .getSkillsByProgramId(programId, version);
          return { data: results?.data };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
    }),
    getProgramInfoByProgramOfferingId: build.query({
      async queryFn(
        { programOfferingId, token }: { programOfferingId: string, token?: string },
        { extra },
      ) {
        try {
          const { ProgramsService } = extra as Services;
          const results = await ProgramsService.setToken(token)
            .setServerSideBaseUrl().getProgramInfoByProgramOfferingId(programOfferingId);
          return { data: results?.data };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
    }),
    getLoaReturnDatesByIrn: build.query({
      async queryFn(
        { irn, loaStartDate, token }:
        { irn: string, loaStartDate: string, token?: string },
        { extra },
      ) {
        const { ProgramsServiceV1 } = extra as Services;
        try {
          const results = await ProgramsServiceV1.setToken(token)
            .setServerSideBaseUrl()
            .getLoaReturnDatesByIrn(irn, loaStartDate);
          return { data: results?.data };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
      providesTags: ['Loa'],
    }),
  }),
});

export const {
  useGetProgramProgressDataByProgramIdQuery,
  useGetSkillsByProgramIdQuery,
  useGetProgramInfoByProgramOfferingIdQuery,
  useGetLoaReturnDatesByIrnQuery,
} = programsApi;
