import Icon from '@/components/atoms/icon';
import StandardLink from '@/components/atoms/standard-link';
import { styled } from '@mui/material/styles';

export const LinkWrapper = styled('div', {
  shouldForwardProp: (prop) => prop[0] !== '$',
})(({ theme, $header, $footer }) => ({
  ...($header && {
    width: 'fit-content',
    '& :hover': {
      '& span': {
        textDecoration: 'underline',
        textDecorationColor: theme.palette.primary.main,
      },
    },
  }),
  ...(!$header && {
    '& :hover': {
      '& span': {
        color: !$footer && theme.palette.primary.main,
        textDecoration: 'underline',
        textDecorationColor: theme.palette.primary.main,
      },
      '& svg': {
        fill: !$footer && theme.palette.primary.main,
      },
    },
  }),
}));

export const StyledLink = styled(StandardLink, {
  shouldForwardProp: (prop) => prop[0] !== '$',
})(({ theme, $header, $footer }) => ({
  ...($header && {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.almostBlack.main,
    fontSize: theme.spacing(1.4),
    lineHeight: theme.spacing(1.6),
  }),
  ...(!$header && {
    textDecoration: 'none',
    fontWeight: '500',
    fontSize: theme.spacing(1.7),
    display: 'inline-block',
    '&:hover': {
      fill: !$footer && theme.palette.primary.main,
    },
  }),
  ...($footer && {
    fontSize: theme.spacing(1.8),
    '&:focus': {
      outline: `${theme.spacing(0.1)} solid black`,
      outlineOffset: theme.spacing(0.3),
    },
  }),
}));

export const StyledIcon = styled(Icon, {
  shouldForwardProp: (prop) => prop[0] !== '$',
})(({ theme, $header }) => ({
  ...(!$header && {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: theme.spacing(1.5),
  }),
}));

export const StyledText = styled('span', {
  shouldForwardProp: (prop) => prop[0] !== '$',
})(({ theme, $header }) => ({
  color: theme.palette.common.black,
  whiteSpace: 'nowrap',
  ...($header && {
    marginTop: theme.spacing(0.8),
  }),
  ...(!$header && {
    position: 'relative',
    top: theme.spacing(0.15),
  }),
}));
