import analytics from '@/helpers/analytics';

export const EVENT_QUEUE_THROTTLE_MS = 5;
export const ERROR_EVENT_NAME = 'myPhoenixErrorEvent';
export const DISPLAY_EVENT_NAME = 'myPhoenixDisplayEvent';
export const CLICK_EVENT_NAME = 'myPhoenixClickEvent';
export const MEASURE_EVENT_NAME = 'myPhoenixMeasureEvent';
export const USER_IDENTIFIED_EVENT_NAME = 'UserIdentified';
export const USER_IDENTIFIED_ENRICHMENT_EVENT_NAME = 'UserIdentifiedEnrichment';
export const COURSE_INFO_START_MARK_NAME = 'courseInfoScenario.start';
export const COURSE_INFO_RENDERED_MARK_NAME = 'courseInfoScenario.rendered';
export const COURSE_INFO_MEASURE_NAME = 'courseInfoScenario';

const IDENTIFY_EVENTS = [
  USER_IDENTIFIED_EVENT_NAME,
  USER_IDENTIFIED_ENRICHMENT_EVENT_NAME,
];

let myPhxHandlersAreInitialized = false;
const preInitializedEventQueue = [];

const dispatchEvent = (myPhxEvent) => {
  if (myPhxHandlersAreInitialized) {
    document.dispatchEvent(myPhxEvent);
  } else {
    preInitializedEventQueue.push(myPhxEvent);
  }
};

if (typeof window !== 'undefined') {
  window.onload = () => {
    // turn of pre-init flag
    myPhxHandlersAreInitialized = true;
    // loop through and dispatch any queued events, but slightly throttled
    let nextEvent = preInitializedEventQueue.shift();
    let throttledDispatchInterval;
    const throttledDispatch = () => {
      if (nextEvent) {
        dispatchEvent(nextEvent);
        nextEvent = preInitializedEventQueue.shift();
      } else {
        clearInterval(throttledDispatchInterval);
      }
    };
    throttledDispatchInterval = setInterval(
      throttledDispatch,
      EVENT_QUEUE_THROTTLE_MS,
    );
  };
}

const getCurrentPage = () => {
  const { Page } = analytics;
  return Page;
};

export const publishMyPhoenixErrorEvent = (componentName, properties) => {
  const eventObject = { componentName, page: getCurrentPage(), properties };
  dispatchEvent(new CustomEvent(ERROR_EVENT_NAME, { detail: eventObject }));
};

export const publishMyPhoenixComponentEvent = (trackingData) => {
  const eventData = { ...trackingData };
  const eventName = eventData?.trackingMethod === 'trackEvent'
    ? CLICK_EVENT_NAME
    : DISPLAY_EVENT_NAME;
  delete eventData.trackingMethod;

  dispatchEvent(new CustomEvent(eventName, { detail: eventData }));
};

export const publishMyPhoenixMeasureEvent = (measurementName, properties) => {
  const eventObject = { measurementName, page: getCurrentPage(), ...properties };
  dispatchEvent(new CustomEvent(MEASURE_EVENT_NAME, { detail: eventObject }));
};

export const createEventObject = (name, properties) => {
  // Work around on properties till full refactor
  const propertiesObject = Array.isArray(properties)
    ? Object.assign({}, ...properties)
    : properties;
  return {
    componentName: name,
    properties: propertiesObject,
  };
};

const handleGtmDataLayerEvent = (eventName, eventData = {}) => {
  if (
    eventName !== USER_IDENTIFIED_EVENT_NAME
    && eventName !== USER_IDENTIFIED_ENRICHMENT_EVENT_NAME
  ) {
    window.dataLayer.push({
      event: eventName,
      ...eventData,
    });
    return;
  }

  for (let index = 0; index < IDENTIFY_EVENTS.length; index += 1) {
    if (
      IDENTIFY_EVENTS[index] === eventName
      && !window.dataLayer?.find((e) => e.event === IDENTIFY_EVENTS[index])
    ) {
      window.dataLayer.push({
        event: eventName,
        ...eventData,
      });
      break;
    }
  }
};

export const dispatchToGTM = (eventName, eventData = {}) => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    handleGtmDataLayerEvent(eventName, eventData);
  }
};
