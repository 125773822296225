// section models
export const UNIVERSITY_CONTACTS = 'university-contact-tabs';
export const MAINTENANCE_PAGE = 'maintenance-page';
export const POLICY_COMPLIANCE = 'policy-and-compliance';

// page models
export const VSU_PAGE_MODEL = 'vsu';

// data models
export const COURSE_INFO = 'course-info';
export const HEADER = 'header';
