import { getCustomTokenFromSessionStorage, getTokenFromSessionStorage } from '@/helpers/auth';
import { getErrorCode, trackTimeoutError } from '@/myphoenix/utils/error-functions';
import builder from '@builder.io/react';

export function getAuthorizationToken(ssrToken = '') {
  if (ssrToken !== '') return ssrToken;

  return (builder.editingMode) ? getCustomTokenFromSessionStorage() : getTokenFromSessionStorage();
}

export function errorTrackingHandler(
  httpStatusCode: number | string,
  requestId: string,
  correlationId: string,
) {
  if (httpStatusCode === 408 || httpStatusCode === 504) {
    const errorCode = getErrorCode();
    trackTimeoutError(errorCode, requestId, correlationId);
  }
}
