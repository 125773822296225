import { getCurrentDate, toDateTime } from '@/myphoenix/utils/date-time-functions';
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import makeAbsoluteUrl from '@/helpers/absolute-url';
import {
  filteredLogonEvents,
  filteredUnreadNonLogonEvents,
} from '@/store/helpers/notifications';
import {
  getLogonEventShouldBeBypassed,
  setLogonEventShouldBeBypassed,
  logonEventRedirect,
} from '@/components/molecules/header-notification/utils';
import { useGetLatestApplicationIdQuery } from '@/store/queries/workflows';
import {
  useGetNotificationsQuery,
  useGetTasksQuery,
  useGetEventsQuery,
} from '@/store/queries/notifications';
import { FeatureVariableKeys } from '@/helpers/features';
import {
  FeatureVariableValueJSON,
  useFeatureVariableValue,
} from '@/helpers/feature-provider';
import NotificationAlert from '@/components/atoms/alert';
import NotificationBell from '@/components/atoms/notification-bell';

type NotificationCountParams = {
  tasks: { assignedTasks: any[]; enrollmentTasks: any[] };
  notifications: any[];
  showEnrollmentTasks: boolean;
};

const getNotificationCount = ({
  tasks,
  notifications,
  showEnrollmentTasks,
}: NotificationCountParams) => {
  const assignedTasksCount = tasks?.assignedTasks?.length || 0;
  const enrollmentTasksCount = showEnrollmentTasks
    ? tasks?.enrollmentTasks?.length || 0
    : 0;
  const unreadNotificationsCount = filteredUnreadNonLogonEvents(notifications)?.length || 0;

  return assignedTasksCount + enrollmentTasksCount + unreadNotificationsCount;
};

function HeaderNotification({
  parentComponentName,
}: {
  parentComponentName: string;
}) {
  const bypassLogonEvent = getLogonEventShouldBeBypassed();
  const showEnrollmentTasks = useFeatureVariableValue<boolean>(
    FeatureVariableKeys.MessageCenterEnrollmentTasks,
    false,
  );
  /* e.g.:
  { templateIds: ['MOCK-BYPASS-BLACKLISTED', 'MCSUB-SALOGON'] }
  */
  const logonEventBypassBlacklist = useFeatureVariableValue<FeatureVariableValueJSON>(
    FeatureVariableKeys.LogonEventBypassBlacklist,
    { },
  );

  const {
    data: { applicationId } = { },
    isLoading: isApplicationQueryLoading,
  } = useGetLatestApplicationIdQuery({
    personId: getPersonIdFromSessionStorage(),
  });

  const { data: tasks } = useGetTasksQuery(
    { personId: getPersonIdFromSessionStorage(), applicationId },
    { skip: isApplicationQueryLoading },
  );

  const { data: events } = useGetEventsQuery({
    personId: getPersonIdFromSessionStorage(),
  });

  const {
    data: notifications,
  } = useGetNotificationsQuery({
    personId: getPersonIdFromSessionStorage(),
  });

  const notificationCount = getNotificationCount({
    tasks,
    notifications,
    showEnrollmentTasks,
  });

  const activeLogonEvents = events?.loginCount > 0 && notifications?.length > 0
    ? filteredLogonEvents(notifications).filter((logonEvent) => {
      const today = getCurrentDate();
      return (
        today >= toDateTime(logonEvent?.states?.startDate)
        && today < toDateTime(logonEvent?.states?.dueDate)
        && logonEvent.states?.status?.action === 'ASSIGNED'
      );
    })
    : [];

  const allLogonEventsAreBypassBlacklisted = events?.loginCount
    ? activeLogonEvents.filter((logonEvent) => (
      (logonEventBypassBlacklist?.templateIds as string[])?.includes(logonEvent.templateId)
    )).length >= events.loginCount
    : false;

  const goToLogonEvent = () => {
    if (!allLogonEventsAreBypassBlacklisted) {
      setLogonEventShouldBeBypassed();
    }
    logonEventRedirect();
  };

  const showAlertNotification = events?.loginCount > 0 && !bypassLogonEvent;

  return (
    <>
      <NotificationBell
        badgeContent={notificationCount}
        href={makeAbsoluteUrl('/message-center.html')}
        parentComponentName={parentComponentName}
      />
      <NotificationAlert
        open={showAlertNotification}
        title="You have an important message."
        content="Please review and take the appropriate action to move forward."
        buttonText="Continue to message"
        onClick={goToLogonEvent}
      />
    </>
  );
}

export default HeaderNotification;
