import { FetchRequestConfig } from '@/services/wrappers/fetch-types';
import { getPreFetchData } from '@/store/helpers/pre-fetch-utils';

const prefetchInterceptor = (requestConfig : FetchRequestConfig) => {
  const preFetchData = getPreFetchData({ url: requestConfig?.url });
  if (preFetchData?.error) {
    return {
      ...requestConfig,
      abortFetch: true,
    };
  }
  return requestConfig;
};

export default prefetchInterceptor;
